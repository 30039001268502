import React from 'react';

import { IconPropsWithSVGProps } from '../type';

const LogoText = (props: IconPropsWithSVGProps) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='702'
    height='118'
    viewBox='0 0 702 118'
    fill='none'
  >
    <path
      d='M53.6711 66.9711H0L77.6242 0H130L53.6711 66.9711Z'
      fill='url(#paint0_linear_4750_2711)'
    />
    <path
      d='M124.217 89.8042C120.094 105.321 104.569 117.256 89.5548 116.483C74.5407 115.71 64.799 102.512 68.9401 86.9949C73.0813 71.4777 89.5 59.5428 104.514 60.3158C119.528 61.0888 128.34 74.287 124.217 89.8042Z'
      fill='url(#paint1_linear_4750_2711)'
    />
    <path
      d='M658.013 116.893C624 114.395 619.5 99.8945 619.5 79.9265V79.8595C622 56.3945 642.663 40.6507 667.5 41.3946C698 42.3082 701.5 67.3945 701.5 73.3945C701.5 79.3945 699.824 85.3502 699.824 85.3502H641C641.3 95.1934 651.816 99.8945 661.5 99.8945C670.058 99.8945 684.5 94.3945 687.952 92.6533L692.824 109.122C680 115.895 667.973 117.625 658.013 116.893ZM664.5 58.3945C658.013 58.0702 645.851 63.3203 644.5 71.3556H680.5C679.149 63.1864 673.988 58.8689 664.5 58.3945Z'
      fill='#20F396'
    />
    <path
      d='M575.922 116.193L591.5 13.8945H613.5L598 116.193H575.922Z'
      fill='#20F396'
    />
    <path
      d='M543.5 116.895C520.885 116.895 512.5 109.395 515 94.4548L521 59.5232H503.5L505.5 42.8945H523L527 17.8945H548L544.5 42.8945H569.5L567.5 59.5232H541.633L538 91.1736C538 97.5994 541.553 98.8945 547.5 98.8945C549.414 98.8945 559.133 96.0996 560.5 95.8945L561.5 112.895C559.5 114.395 556 116.895 543.5 116.895Z'
      fill='#20F396'
    />
    <path
      d='M455.5 117.395C433.352 117.395 422.432 114.279 412.5 106.395L422.5 91.9414L423 92.3945C435 100.427 448.891 100.395 456 100.395C462.426 100.395 469.5 97.291 469.5 93.4629V93.3945C469.5 90.0449 466.213 91.0115 459.582 89.6443L446.457 87.0466C431.896 84.2439 422.5 79.3945 422.5 66.0603C422.5 51.2263 437.129 40.8945 457.5 40.8945C479.033 40.8945 488.5 46.3945 493 50.8945L485 63.8945C479.932 61.3477 470 57.8945 460 57.8945C450.5 57.8945 444.5 59.998 444.5 63.8945V63.9629C444.5 67.1758 451.5 68.8945 457 69.8945L470 71.8945C485.791 75.0391 492 79.752 492 90.8945V90.9629C492 106.344 475.939 117.395 455.5 117.395Z'
      fill='#20F396'
    />
    <path
      d='M399.792 24.8574C392.819 24.8574 387.419 19.3887 387.419 12.8945C387.419 6.40039 392.819 1 399.792 1C406.833 1 412.165 6.40039 412.165 12.8945C412.165 19.3887 406.833 24.8574 399.792 24.8574ZM375 115.395L385.5 43.3945H407L397 115.395H375Z'
      fill='#20F396'
    />
    <path
      d='M280.5 115.394L296 13.8943H317L311 55.1482H311.5C318 45.3945 332.289 40.8945 343.5 40.8945C349 41.3945 366.5 46.3945 363 68.8943L355 115.394H333.988L340.5 73.8943C342.5 58.3945 330.5 59.3945 326.5 59.3945C318.844 59.3945 308.5 64.8945 307.5 76.3943L301.5 115.394H280.5Z'
      fill='#20F396'
    />
    <path
      d='M166.5 115.394L153 42.8943H174.5L182 88.8943H183L207 42.8943H224L233 89.8943H234L255.5 42.8943H278.5L241.5 115.394H219L212.5 70.3943H211L188.5 115.394H166.5Z'
      fill='#20F396'
    />
    <defs>
      <linearGradient
        id='paint0_linear_4750_2711'
        x1='4.18505'
        y1='66.7695'
        x2='137.162'
        y2='-0.650249'
        gradientUnits='userSpaceOnUse'
      >
        <stop stop-color='#20F396' />
        <stop offset='1' stop-color='#000601' />
      </linearGradient>
      <linearGradient
        id='paint1_linear_4750_2711'
        x1='70.6493'
        y1='99.6302'
        x2='130.19'
        y2='85.6326'
        gradientUnits='userSpaceOnUse'
      >
        <stop stop-color='#20F396' />
        <stop offset='1' stop-color='#00FF99' />
      </linearGradient>
    </defs>
  </svg>
);

export default LogoText;
