import React from 'react';

import { IconPropsWithSVGProps } from '../type';

const Plus = (props: IconPropsWithSVGProps) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='30'
    height='30'
    viewBox='0 0 30 30'
    fill='none'
  >
    <rect y='13.5' width='30' height='2.25' rx='1.125' fill='currentColor' />
    <rect
      x='13.5'
      y='30'
      width='30'
      height='2.25'
      rx='1.125'
      transform='rotate(-90 13.5 30)'
      fill='currentColor'
    />
  </svg>
);

export default Plus;
