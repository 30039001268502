import React from "react";

import { IconPropsWithSVGProps } from "../type";

const CheckBox = (props: IconPropsWithSVGProps) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.3334 10C18.3334 5.39763 14.6025 1.66667 10.0001 1.66667C5.39771 1.66667 1.66675 5.39763 1.66675 10C1.66675 14.6024 5.39771 18.3333 10.0001 18.3333C14.6025 18.3333 18.3334 14.6024 18.3334 10Z"
      stroke="#00FF99"
    />
    <path
      d="M6.66675 10.625C6.66675 10.625 8.00008 11.3854 8.66675 12.5C8.66675 12.5 10.6667 8.125 13.3334 6.66667"
      stroke="#00FF99"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CheckBox;
