import { Flex, Margin, Container, Head } from '@/styles/style';
import CenterTable from '@/components/Tables/CenterTable';
const CenterListPage = () => {
  return (
    <Flex>
      <Container>
        <Head>센터 목록</Head>
        <Margin margin='30px 0 0' />
        <CenterTable />
      </Container>
    </Flex>
  );
};
export default CenterListPage;
