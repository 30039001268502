import { useState, useEffect } from 'react';
import {
  Flex,
  CircleImage,
  SelectWrapper,
  NullDataBox,
  Select,
  Option,
  Button,
  Span,
} from '@/styles/style';
import * as T from '@/styles/TableStyle';
import { ListInfoType } from '../type';
import { CenterType, ListInfoTypeDefaultValue } from '@/constants/type';
import { customFetch } from '@/constants/customFetch';
import { CenterDetailModal } from '@/components/Modals/CenterDetailModal';

export const ManageTable = () => {
  const [listInfo, setListInfo] = useState<ListInfoType>(
    ListInfoTypeDefaultValue
  );
  const [list, setList] = useState<CenterType[]>([]);
  const [offset, setOffset] = useState<number>(0);
  const [currentPagination, setCurrentPagination] = useState<number>(1);
  const [orderBy, setOrderBy] = useState<'desc' | 'asc'>('desc');
  const [role, setRole] = useState<'all' | 'staff' | 'member' | 'owner'>('all');
  const [modalShow, setModalShow] = useState<boolean>(false);
  const [selectedData, setSelectedData] = useState<CenterType>();

  useEffect(() => {
    getData();
  }, []);
  useEffect(() => {
    getData();
  }, [offset]);
  useEffect(() => {
    setOffset((currentPagination - 1) * 10);
  }, [currentPagination]);
  const getData = async () => {
    const response = await customFetch({
      url: '/api/admin/center/proposalList',
      body: {
        limit: 10,
        offset,
        orderBy,
        role,
      },
    });
    if (response) {
      const { listInfo, listCenter } = response;
      if (listInfo) {
        setListInfo(listInfo);
      }
      if (listCenter) {
        setList(listCenter);
      } else {
        setList([]);
      }
    }
  };
  const confirmStaff = async (itemId: string, value: string) => {
    const response = await customFetch({
      url: '/api/admin/center/confirm',
      body: {
        centerId: itemId,
        connRegStatus: value,
      },
    });
    const result = response;
    if (!result) return;
    window.location.reload();
  };
  return (
    <>
      <Flex justifyContents='space-between'>
        <Span color='var(--c100)'>
          총 {listInfo.totalCount || 0}개의 데이터
        </Span>
      </Flex>
      <T.Container>
        <T.TableContainer>
          <T.Table>
            <T.THead>
              <T.Tr>
                <T.Th width='50px' textAlign='center'>
                  번호
                </T.Th>
                <T.Th>센터명</T.Th>
                <T.Th>대표자</T.Th>
                <T.Th>연락처</T.Th>
                <T.Th>위치</T.Th>
                <T.Th>신청일</T.Th>
                <T.Th>상태 변경</T.Th>
              </T.Tr>
            </T.THead>
            <T.TBody>
              {list.length > 0 ? (
                list.map((item, index) => {
                  return (
                    <T.Tr
                      key={index}
                      onClick={() => {
                        setModalShow(true);
                        setSelectedData(item);
                      }}
                    >
                      <T.Td textAlign='center'>{offset + index + 1}</T.Td>
                      <T.Td>
                        <T.ProfileBox>
                          <CircleImage size='31px'>
                            <img
                              src={
                                item.bannerImages[0].thumbnailImage ||
                                '/defaultUserImage.png'
                              }
                              alt='프로필 이미지'
                              onError={(e) => {
                                const target = e.target as HTMLImageElement;
                                target.onerror = null; // 재귀적인 호출을 방지
                                target.src = '/defaultUserImage.png';
                              }}
                            />
                          </CircleImage>
                          {item.displayName}
                        </T.ProfileBox>
                      </T.Td>
                      <T.Td>{item.ownerName}</T.Td>
                      <T.Td>{item.centerPhone}</T.Td>
                      <T.Td>
                        {item.location?.fullAddress.replace('|', ' ')}
                      </T.Td>
                      <T.Td>{item?.createdAt?.split('T')[0]}</T.Td>
                      <T.Td>
                        <Flex gap='5px'>
                          <Button
                            border='1px solid var(--semantic-red)'
                            color='var(--semantic-red)'
                            onClick={(e: any) =>
                              confirmStaff(item.centerId, 'inactivated')
                            }
                          >
                            거절
                          </Button>
                          <Button
                            border='1px solid var(--primary)'
                            color='var(--primary)'
                            onClick={(e: any) =>
                              confirmStaff(item.centerId, 'activated')
                            }
                          >
                            승인
                          </Button>
                        </Flex>
                      </T.Td>
                    </T.Tr>
                  );
                })
              ) : (
                <T.Tr>
                  <T.Td colSpan={6}>
                    <NullDataBox>요청이 없습니다</NullDataBox>
                  </T.Td>
                </T.Tr>
              )}
            </T.TBody>
          </T.Table>
        </T.TableContainer>
        <T.PaginationContainer>
          {Array.from(
            { length: (listInfo?.totalCount - 1) / listInfo?.limit + 1 },
            (_, i) => i + 1
          ).map((value, index) => {
            return (
              <T.PaginationItem
                key={index}
                isActive={value === currentPagination}
                onClick={() => setCurrentPagination(value)}
              >
                {value}
              </T.PaginationItem>
            );
          })}
        </T.PaginationContainer>
      </T.Container>
      {
        <CenterDetailModal
          data={selectedData}
          modalShow={modalShow}
          setModalShow={setModalShow}
        />
      }
    </>
  );
};
