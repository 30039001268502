import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
const firebaseConfig = {
  apiKey: 'AIzaSyCZ1BNRaJiFXqnw64SSE3I0xRZqQS8tdrM',
  authDomain: 'whistle-pro-f7cce.firebaseapp.com',
  projectId: 'whistle-pro-f7cce',
  storageBucket: 'whistle-pro-f7cce.appspot.com',
  messagingSenderId: '282257427084',
  appId: '1:282257427084:web:2f623211fe1fc8e0e149a9',
  measurementId: 'G-843NL4QRQC',
};

export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
