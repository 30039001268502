import {
  CircleImage,
  Flex,
  NullDataBox,
  SearchInput,
  Span,
} from '@/styles/style';
import * as T from '@/styles/TableStyle';
import { useEffect, useState } from 'react';
import { selectItem } from '../common';
import { ListInfoType, TableType } from '../type';
import { customFetch } from '@/constants/customFetch';
import { CenterType, ListInfoTypeDefaultValue } from '@/constants/type';
import { CenterDetailModal } from '@/components/Modals/CenterDetailModal';
import useCustomTable from '@/hooks/useCustomTable';
import Pagination from '@/components/Pagination';
type CenterTableType = {
  onlyOneSelected?: boolean;
} & TableType;
const CenterTable = ({
  selectedItemList,
  setSelectedItemList,
  canCheck,
  showOnlySelected,
  onlyOneSelected,
  canSearch,
  keyword,
  setKeyword,
}: CenterTableType) => {
  const [currentPagination, setCurrentPagination] = useState<number>(1);
  const [orderBy, setOrderBy] = useState<'desc' | 'asc'>('desc');
  const [role, setRole] = useState<'all' | 'staff' | 'member' | 'owner'>('all');
  const [modalShow, setModalShow] = useState<boolean>(false);
  const [selectedData, setSelectedData] = useState<CenterType>();

  const { list, setList, listInfo, offset, setOffset } = useCustomTable({
    itemType: 'center',
    showOnlySelected,
    selectedItemList,

    currentPagination,
    orderBy,
    role,
    keyword,
    setKeyword,
  });

  return (
    <>
      <Flex justifyContents='space-between'>
        <Span color='var(--c100)'>
          총 {listInfo?.totalCount || 0}
          개의 데이터
        </Span>
      </Flex>
      <T.Container>
        <T.TableContainer>
          <T.Table>
            <T.THead>
              <T.Tr>
                {canCheck && <T.Th width='50px'>선택</T.Th>}
                <T.Th width='50px' textAlign='center'>
                  번호
                </T.Th>
                <T.Th>센터명</T.Th>
                <T.Th>신청상태</T.Th>
                <T.Th>주소</T.Th>
                <T.Th>연락처</T.Th>
                <T.Th>가입일</T.Th>
              </T.Tr>
            </T.THead>
            <T.TBody>
              {list.length > 0 ? (
                list.map((item, index) => {
                  return (
                    <T.Tr
                      key={index}
                      onClick={() => {
                        if (!canCheck) {
                          setModalShow(true);
                          setSelectedData(item);
                        }
                      }}
                    >
                      {canCheck && selectedItemList && setSelectedItemList && (
                        <T.Td>
                          <input
                            type='checkbox'
                            checked={selectedItemList.some(
                              (selectedItem) =>
                                selectedItem.centerId === item.centerId
                            )}
                            onChange={() =>
                              selectItem(
                                item,
                                selectedItemList,
                                setSelectedItemList,
                                onlyOneSelected
                              )
                            }
                          />
                        </T.Td>
                      )}
                      <T.Td textAlign='center'>{offset + index + 1}</T.Td>
                      <T.Td>
                        <T.ProfileBox>
                          <CircleImage size='31px'>
                            <img
                              src={
                                (item?.bannerImages?.length > 0 &&
                                  item?.bannerImages[0].thumbnailImage) ||
                                '/defaultUserImage.png'
                              }
                              alt='프로필 이미지'
                              onError={(e) => {
                                const target = e.target as HTMLImageElement;
                                target.onerror = null; // 재귀적인 호출을 방지
                                target.src = '/defaultUserImage.png';
                              }}
                            />
                          </CircleImage>
                          {item.displayName}
                        </T.ProfileBox>
                      </T.Td>
                      <T.Td>{item.activateState}</T.Td>
                      <T.Td>
                        {item?.location?.fullAddress.replace('|', ' ')}
                      </T.Td>
                      <T.Td>{item.ownerPhone}</T.Td>
                      <T.Td>{item.createdAt?.split('T')[0]}</T.Td>
                    </T.Tr>
                  );
                })
              ) : (
                <T.Tr>
                  <T.Td colSpan={6}>
                    <NullDataBox>데이터가 없습니다</NullDataBox>
                  </T.Td>
                </T.Tr>
              )}
            </T.TBody>
          </T.Table>
        </T.TableContainer>
        {!showOnlySelected && listInfo && (
          <Pagination
            listInfo={listInfo}
            currentPagination={currentPagination}
            setCurrentPagination={setCurrentPagination}
          />
        )}
      </T.Container>
      {
        <CenterDetailModal
          data={selectedData}
          modalShow={modalShow}
          setModalShow={setModalShow}
        />
      }
    </>
  );
};
export default CenterTable;
