import styled from 'styled-components';
export const ImageContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
  overflow-x: auto;
  padding: 5px 0 7px;
`;
export const ImageWarpper = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 10px;
  border: 1px solid white;
  overflow: hidden;
  flex-shrink: 0;
  > img {
    object-fit: contain;
    object-position: left;
  }
`;
export const ViewRegImage = styled.div`
  height: 50px;
  width: 140px;
  font-size: 1.4rem;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: gray;
  border-radius: 10px;
  cursor: pointer;
`;
