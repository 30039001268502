import { ListInfoType } from '@/constants/type';
import React from 'react';
import * as T from '@/styles/TableStyle';
import Icon from '@/components/Icon';
import { range } from '@/constants/common';

type PaginationType = {
  listInfo: ListInfoType;
  currentPagination: number;
  setCurrentPagination: React.Dispatch<React.SetStateAction<number>>;
};
const Pagination = ({
  listInfo,
  currentPagination,
  setCurrentPagination,
}: PaginationType) => {
  const endPagination = Math.floor(
    (listInfo.totalCount - 1) / listInfo.limit + 1
  );
  if (endPagination > 0) {
    return (
      <T.PaginationContainer>
        <button disabled={!(currentPagination > 1)}>
          <Icon
            icon='ArrowDown'
            width={13}
            height={13}
            rotate={90}
            color={currentPagination > 1 ? 'white' : 'var(--c500)'}
            onClick={() =>
              currentPagination > 1 &&
              setCurrentPagination(currentPagination - 1)
            }
          />
        </button>
        {range(
          Math.floor((currentPagination - 1) / 5) * 5 + 1,
          Math.floor((currentPagination - 1) / 5) * 5 + 5 > endPagination
            ? endPagination
            : Math.floor((currentPagination - 1) / 5) * 5 + 5,
          1
        ).map((value, index) => {
          return (
            <T.PaginationItem
              key={index}
              isActive={value === currentPagination}
              onClick={() => setCurrentPagination(value)}
            >
              {value}
            </T.PaginationItem>
          );
        })}
        <button disabled={!(currentPagination < endPagination)}>
          <Icon
            icon='ArrowDown'
            width={13}
            height={13}
            rotate={270}
            color={currentPagination < endPagination ? 'white' : 'var(--c500)'}
            onClick={() =>
              currentPagination < endPagination &&
              setCurrentPagination(currentPagination + 1)
            }
          />
        </button>
      </T.PaginationContainer>
    );
  } else {
    return null;
  }
};
export default Pagination;
