export type CenterType = {
  centerId: string;
  displayName: string;
  staffs: ProfileType[];
  centerMemCount: number;
  centerGrade: 'premium' | 'free'; // premium, free --> Free 라고 뱃지 표시, [NEW] [Premium] ---> 최초 생성 시 default = free
  hasNewBadge: boolean; // approvedAt 이 10일 이내 일 경우.. true 아니면 false
  introduce: {
    text: string;
  };
  location: {
    fullAddress: string;
    latitude: string;
    longitude: string;
  };
  previewSessions: SessionType[]; //센터 메인 화면(비가입)의 세션 리스트
  centerPhone?: string;
  captions: CaptionType[];
  centerProfileImage: ImageType;
  bannerIds: string[];
  bannerImages: ImageType[];
  sportsTagIds: SportsTagType[];
  activateState:
    | 'requested'
    | 'activated'
    | 'inactivated'
    | 'pending'
    | 'cancelled'; // requested, activated, inactivated, pending, cancelled

  //대표자 정보
  ownerAccountId: string;
  ownerName: string;
  ownerPhone: string;

  //사업자등록
  regNumber: string;
  businessRegImage: ImageType;

  isDeleted: boolean;
  startedAt: string; //사업자 등록일 "YYYY-MM-dd'T'HH:mm:ss.zzzZ"
  createdAt: string; //데이터 추가일
  approvedAt: string; // 승인 일자
  modifiedAt: string; //수정 일자

  myProfile: ProfileType;
  terms: TermType[];
};

export type SessionType = {
  sessionId: string;
  centerId: string;
  center: CenterType; // API 에서 join
  authority: 'public' | 'member_only'; // public, member_only  --> MemberOnly 면 Premium / public 은 아무것도 없이
  title: string;

  introduceText: string;
  preperationList: PreperationType[];
  introduces: {
    title: string;
    context: string;
  }[];
  displayImage: ImageType; // 세션 배너 이미지

  // 스트리밍 부분
  video: VideoType;
  isFliped: boolean; // 좌우 반전 default 세팅
  scenes: SceneType[];

  // 스포츠 태그
  sportsTagIds: string[];

  // 저작권 정보
  copyrightText: string;

  // 관리 부분
  uploadUserId?: string;
  feedbackAlertUserIds: string[]; // 피드백 요청 알림을 받을 관리자 계정들
  streamingStatus:
    | 'ready_forUpload'
    | 'upload_failed'
    | 'uploaded'
    | 'converting'
    | 'completed'; // ready_forUpload, upload_failed, uploaded, converting, completed,
  isSessionCompleted: boolean; // Session Media, Scene Media 완료 되었을 때, 서빙 가능한 상황
  isDeleted: boolean;
  createdAt: string;
  updatedAt: string;

  // 완료한 사람들
  memberProfileDataCount: number;
  memberProfileData: ImageType[];

  // 내가 완료했는지 flag
  myFeedbackId: string | null;
};

export type ProfileType = {
  userKey: string;
  centerId: string;
  email?: string;
  phoneNumber?: string;
  profileImage: ImageType; //정사각형
  nickname: string; //3~10자, (_, -), 한글, 영문, 숫자,
  role: string; //owner, staff, member, guest
  permissionState: string; //pending, activated, inactivated
  isAdult: boolean;
  isDeleted: boolean;
  createdAt: string;
  modifiedAt: string;
};

export type PostType = {
  postId: string;
  centerId: string;
  centerName: string;
  likeCount: number;
  replyCount: number;
  content: string;
  isDeleted: boolean;
  isNoti: boolean;
  image: ImageType | null;
  video: VideoType | null;
  authorProfile: ProfileType;
  createdAt: string;

  // header 에 따라
  isMine: boolean;
  isLikeExist: boolean;

  watchingProfile: ProfileType;
};

export type ReplyType = {
  replyId: string;
  parentReplyId: string;
  postId: string;
  centerId: string;
  likeCount: number;
  subReplyCount: number;
  content: string;
  createdAt: string;
  isDeleted: boolean;
  authorProfile: ProfileType;

  isMine: boolean;
  isLikeExist: boolean;
};

export type FeedbackType = {
  feedbackId: string;
  feedbackStatus: 'requested' | 'responsed' | 'completed';
  feedbackContent: string;
  feedbackVideo: VideoType;

  // Ref 정보
  centerId: string;
  sessionId: string;
  sessionAuthority: 'memberOnly' | 'public';
  sceneId: string;
  scene: SceneType;

  // 요청자 Profile
  userKey: string;
  requestedBy: ProfileType;

  //유저 세팅
  feedbackAlarm: true;
  isReverse: false;
  isFeedbackResView: true; // 응답 봤는지
  isVisibility: true; // 센터 피드 노출 여부
  showClass: 'Friends'; // 보이기 구분 string Friends = 친구 Experts = 전문가

  // 응답
  responseId: string;
  response: {
    // 응답자 Profile
    responsedBy: ProfileType;
    video: VideoType | null;
    image: ImageType | null;
    content: string;
    isResMediaCompleted: false;
  };

  createdAt: string;
  modifiedAt: string;
};
export type SceneType = {
  sceneId?: string;
  sceneType: 'custom_scene' | 'coaching'; // customScene, coaching
  sceneStartAt: number; // 씬이 시작하는 시간 (ms 기준)
  sceneEndAt: number; // 씬이 끝나는 시간 (ms 기준)
  title: string | null;
  thumbnailUrl: string; // 씬 start at 의 frame 이미지
  thumbnailFile?: Blob; //
  additionalVideo?: VideoType;
  additionalMent: string | null;
  additionalStreamingDuration: number | null;
  additionalStreamingRatio?: 'landscape' | 'portrait';
  additionalStreamingStatus?: string;

  canSkipStep: boolean;
  thumbnailUrlChange?: boolean;
  sessionId?: string;
};
export type ImageType = {
  id?: string;
  thumbnailImage: string;
  largeImage: string;
  mediumImage: string;
  originalImage: string;
};

export type VideoType = {
  ratio: 'portrait' | 'landscape'; // portrait, landscape,
  streamingUrl: string;
  convertStatus: string; // readyForUpload, uploaded, processing, completed, failed,
  duration: number; // ms 단위
  thumbnailImage?: ImageType;
  metadata: {
    originalWidth: number;
    originalHeight: number;
  };

  videoKey: string; // 서버 내부 관리용
  isCompleted: boolean; // 서버 내부 관리용
};

export type AccountType = {
  accountId?: string;
  userKey: string;
  phoneNumber: string; // masked
  username: string; // email @ 앞의 내용
  email: string;
  isAdult: boolean;
  createdAt: string;
};

export type PreperationType = {
  preperationId?: string;
  title: string;
  isNecessary: boolean;
  iconId: string;
  iconUrl: string;
};
export type CaptionType = {
  text: string;
  iconId: string;
  iconImageUrl: string;
};

// frontend 추가
export type SportsTagType = {
  tagId: string;
  tagName: string;
  tagImage: ImageType;
};

export type TermType = {
  termId: number;
  name: string;
  url: string;
  required: boolean;
  isViewCkOk: boolean;
};

export type SessionFormType = {
  title: string;
  mainVideo?: File;
  introduceText: string;
  authority: string;
  displayImageFile: File;
  introducesTitle: string;
  introducesContext: string;
  prepare: any;
};
export type ListInfoType = {
  count: number;
  isLastOffset: boolean;
  limit: number;
  offset: number;
  totalCount: number;
};

//default value
export const ListInfoTypeDefaultValue = {
  count: 0,
  isLastOffset: false,
  limit: 0,
  offset: 0,
  totalCount: 0,
};
export type SectionType = {
  id: string;
  title: string;
  subtitle: string;
  type: SectionCategoryType;
  items: [
    {
      sectionsId: string;
      type: SectionCategoryType;
      itemId: string;
    },
    {
      sectionsId: string;
      type: SectionCategoryType;
      itemId: string;
    }
  ];
  isHidden: boolean;
  isDeleted: boolean;
  createdAt: string;
  index: number;
  itemExposeType: 'manual' | 'randomPublicSession' | 'randomCenter';
  itemExposeCount: 2;
};
export type SectionCategoryType =
  | 'account'
  | 'profile'
  | 'sportsTag'
  | 'center'
  | 'session'
  | 'actionLog'
  | 'feedback'
  | 'post';
