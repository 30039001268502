import React from 'react';

import { IconPropsWithSVGProps } from '../type';

const RightArrow = (props: IconPropsWithSVGProps) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='12'
    height='8'
    viewBox='0 0 12 8'
    fill='none'
  >
    <g id='Arrow'>
      <path
        id='Vector'
        d='M10.667 3.99988L0.666992 3.99988'
        stroke='#D3D4DC'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        id='Vector 6907'
        d='M8 0.666595L10.6262 3.29282C10.9596 3.62616 11.1262 3.79282 11.1262 3.99993C11.1262 4.20704 10.9596 4.3737 10.6262 4.70704L8 7.33326'
        stroke='#D3D4DC'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
  </svg>
);

export default RightArrow;
