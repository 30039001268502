import styled from 'styled-components';
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
export const TableContainer = styled.div`
  width: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 30px;
`;
export const Table = styled.table`
  width: 100%;
  border: none;
  padding: 10px 0;
`;
export const THead = styled.thead`
  border-bottom: 1px solid var(--c700);
`;
export const TBody = styled.tbody``;
type ThType = {
  width?: string;
  textAlign?: string;
};
export const Th = styled.th<ThType>`
  width: ${({ width }) => width};
  white-space: nowrap;
  padding: 15px 10px;
  vertical-align: middle;
  text-align: ${({ textAlign }) => (textAlign ? textAlign : 'left')};
  font-weight: 500;
`;
export const Tr = styled.tr`
  :not(:last-child) {
    border-bottom: 1px solid var(--c700);
  }
`;
type TdType = {
  textAlign?: string;
  whiteSpace?: string;
};
export const Td = styled.td<TdType>`
  max-width: 400px;
  white-space: ${({ whiteSpace }) => (whiteSpace ? whiteSpace : 'nowrap')};
  padding: 15px 10px;
  height: 61px;
  vertical-align: middle;
  text-align: ${({ textAlign }) => textAlign};
`;
export const PaginationContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 15px;
`;
type PaginationItemType = {
  isActive: boolean;
};
export const PaginationItem = styled.div<PaginationItemType>`
  color: ${({ isActive }) => (isActive ? 'white' : 'var(--c300)')};
  font-size: 1.4rem;
  cursor: pointer;
`;
type RoleBoxType = {
  role: string;
};
export const RoleBox = styled.span<RoleBoxType>`
  padding: 6px 13px;
  justify-content: center;
  align-items: center;
  border: 1px solid
    ${(props) => {
      switch (props.role) {
        case 'owner':
          return 'white';
        case 'staff':
          return '#ffe000';
        case 'member':
          return 'var(--primary)';
        default:
          return 'var(--c300)';
      }
    }};
  color: ${(props) => {
    switch (props.role) {
      case 'owner':
        return 'white';
      case 'staff':
        return '#ffe000';
      case 'member':
        return 'var(--primary)';
      default:
        return 'var(--c300)';
    }
  }};
  font-size: 1.3rem;
  border-radius: 20px;
`;
export const ProfileBox = styled.div`
  display: flex;
  gap: 13px;
  align-items: center;
  justify-content: left;
`;

export const NotiButton = styled.div`
  display: flex;
  width: 35px;
  height: 26px;
  padding: 4px 5px 5px 5px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 6.5px;
  background: var(--c100);
  color: #000;
  text-align: center;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.5px;
  flex-shrink: 0;
`;
