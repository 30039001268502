import React from 'react';

import { IconPropsWithSVGProps } from '../type';

const PauseButton = (props: IconPropsWithSVGProps) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
  >
    <g id='31-pause'>
      <path
        id='Vector'
        d='M8 3.75H7C5.75736 3.75 4.75 4.75736 4.75 6V18C4.75 19.2426 5.75736 20.25 7 20.25H8C9.24264 20.25 10.25 19.2426 10.25 18V6C10.25 4.75736 9.24264 3.75 8 3.75Z'
        stroke='white'
        strokeWidth='1.5'
      />
      <path
        id='Vector_2'
        d='M17 3.75H16C14.7574 3.75 13.75 4.75736 13.75 6V18C13.75 19.2426 14.7574 20.25 16 20.25H17C18.2426 20.25 19.25 19.2426 19.25 18V6C19.25 4.75736 18.2426 3.75 17 3.75Z'
        stroke='white'
        strokeWidth='1.5'
      />
    </g>
  </svg>
);

export default PauseButton;
