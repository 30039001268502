import _ from 'lodash';

export const selectItem = (
  value: any,
  selectedItemList: any[],
  setSelectedItemList: React.Dispatch<React.SetStateAction<any[]>>,
  onlyOneSelected?: boolean
) => {
  let updatedList;
  if (onlyOneSelected) {
    // 하나만 선택가능할 때
    setSelectedItemList([value]);
  } else {
    // selectedItemList에서 value와 동일한 항목 찾기
    const index = selectedItemList.findIndex((item) => _.isEqual(item, value));

    if (index !== -1) {
      // value를 제거
      updatedList = [
        ...selectedItemList.slice(0, index),
        ...selectedItemList.slice(index + 1),
      ];
    } else {
      // value를 추가
      updatedList = [...selectedItemList, value];
    }

    setSelectedItemList(updatedList);
  }
};
