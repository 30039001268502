import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';

type useAuthCheckStoreState = {
  authCheck: boolean;
  setAuthCheck: (input: boolean) => void;
};

export const useAuthCheckStore = create<useAuthCheckStoreState>()(
  persist(
    (set) => ({
      authCheck: false,
      setAuthCheck: (input: boolean) => set(() => ({ authCheck: input })),
    }),
    {
      name: 'authCheck',
      storage: createJSONStorage(() => localStorage),
    }
  )
);
