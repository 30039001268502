import { customFetch } from '@/constants/customFetch';
import { ListInfoTypeDefaultValue, SectionType } from '@/constants/type';
import { Flex, CircleImage, NullDataBox } from '@/styles/style';
import * as T from '@/styles/TableStyle';
import React, { Dispatch } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Button } from '@/styles/style';
import styled from 'styled-components';
import { ListInfoType } from '../type';
type Type = {
  isEdit: boolean;
  setIsEdit: React.Dispatch<React.SetStateAction<boolean>>;
  changeOrder: { id: string; index: number }[];
  setChangeOrder: React.Dispatch<
    React.SetStateAction<{ id: string; index: number }[]>
  >;
};
export const SectionTable = ({
  isEdit,
  setIsEdit,
  changeOrder,
  setChangeOrder,
}: Type) => {
  const [listInfo, setListInfo] = useState<ListInfoType>(
    ListInfoTypeDefaultValue
  );
  const [list, setList] = useState<SectionType[]>([]);
  const [offset, setOffset] = useState<number>(0);
  const [currentPagination, setCurrentPagination] = useState<number>(1);
  const [orderBy, setOrderBy] = useState<'desc' | 'asc'>('desc');
  const [role, setRole] = useState<'all' | 'staff' | 'member' | 'owner'>('all');
  const [modalShow, setModalShow] = useState<boolean>(false);
  const [selectedData, setSelectedData] = useState<SectionType>();

  useEffect(() => {
    getData();
  }, []);
  useEffect(() => {
    setChangeOrder(list.map((v) => ({ id: v.id, index: v.index })));
  }, [list]);
  useEffect(() => {
    getData();
  }, [offset]);
  useEffect(() => {
    setOffset((currentPagination - 1) * 10);
  }, [currentPagination]);
  const getData = async () => {
    const response = await customFetch({
      url: '/api/admin/section/dataList',
      body: {
        limit: 10,
        offset,
        orderBy,
      },
    });
    const { listInfo, adminListSections } = response;
    if (listInfo) {
      setListInfo(listInfo);
    }
    if (adminListSections) {
      setList(adminListSections);
    } else {
      setList([]);
    }
  };
  const handleInput = (id: string, index: number, value: number) => {
    const newOrder = [...changeOrder];
    newOrder[index].index = value;
    console.log(newOrder);
    setChangeOrder([...newOrder]);
  };
  const deleteSection = async (sectionId: string) => {
    const response = await customFetch({
      url: `/api/admin/section/${sectionId}/delete`,
    });
    if (response.status && response.status !== 200) {
      alert('삭제에 문제가 발생하였습니다.');
    } else {
      window.location.reload();
    }
  };
  return (
    <T.Container>
      <T.TableContainer>
        <T.Table>
          <T.THead>
            <T.Tr>
              <T.Th textAlign='center'>순서</T.Th>
              <T.Th>타이틀</T.Th>
              <T.Th>서브타이틀</T.Th>
              <T.Th>타입</T.Th>
              <T.Th>노출타입</T.Th>
              <T.Th textAlign='center'>노출개수</T.Th>
              <T.Th>삭제</T.Th>
            </T.Tr>
          </T.THead>
          <T.TBody>
            {list.length > 0 ? (
              list.map((item, index) => {
                return (
                  <T.Tr key={index}>
                    <T.Td textAlign='center'>
                      {isEdit ? (
                        <Input
                          type='number'
                          defaultValue={item.index}
                          onChange={(e) =>
                            handleInput(item.id, index, Number(e.target.value))
                          }
                        />
                      ) : (
                        item.index
                      )}
                    </T.Td>
                    <T.Td>{item.title}</T.Td>
                    <T.Td>{item.subtitle}</T.Td>
                    <T.Td>{item.type}</T.Td>
                    <T.Td>{item.itemExposeType}</T.Td>
                    <T.Td textAlign='center'>{item.itemExposeCount}</T.Td>
                    <T.Td>
                      <Button
                        backgroundColor='var(--semantic-red)'
                        color='white'
                        border='none'
                        onClick={() =>
                          window.confirm(
                            `${item.index}번 섹션을 삭제하시겠습니까?`
                          ) && deleteSection(item.id)
                        }
                      >
                        삭제
                      </Button>
                    </T.Td>
                  </T.Tr>
                );
              })
            ) : (
              <T.Tr>
                <T.Td colSpan={6}>
                  <NullDataBox>섹션이 없습니다</NullDataBox>
                </T.Td>
              </T.Tr>
            )}
          </T.TBody>
        </T.Table>
      </T.TableContainer>
    </T.Container>
  );
};
const Input = styled.input`
  width: 55px;
  background-color: transparent;
  padding: 5px 10px;
  color: white;
  border: 1px solid var(--c300);
`;
