import React from "react";

import { IconPropsWithSVGProps } from "../type";

const Alarm = (props: IconPropsWithSVGProps) => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.89669 1.13436L2.89268 0.107692C1.20762 1.42154 0.0982949 3.44615 0 5.74359H1.40421C1.45395 4.8331 1.70389 3.9458 2.13543 3.14776C2.56698 2.34973 3.169 1.66152 3.89669 1.13436ZM12.5958 5.74359H14C13.8947 3.44615 12.7854 1.42154 11.1073 0.107692L10.1103 1.13436C10.8349 1.66411 11.4342 2.35308 11.8643 3.15069C12.2944 3.9483 12.5444 4.83428 12.5958 5.74359ZM11.2126 6.10256C11.2126 3.89846 10.0612 2.05333 8.05316 1.56513V1.07692C8.05316 0.481026 7.58275 0 7 0C6.41725 0 5.94684 0.481026 5.94684 1.07692V1.56513C3.9318 2.05333 2.78736 3.89128 2.78736 6.10256V9.69231L1.38315 11.1282V11.8462H12.6169V11.1282L11.2126 9.69231V6.10256ZM7 14C7.0983 14 7.18957 13.9928 7.28084 13.9713C7.73721 13.8708 8.10933 13.5549 8.29188 13.1241C8.36209 12.9518 8.39719 12.7651 8.39719 12.5641H5.58877C5.59579 13.3538 6.22066 14 7 14Z"
      fill="white"
    />
  </svg>
);

export default Alarm;
