import { Flex, Margin, Head, Container } from '@/styles/style';
import SessionTable from '@/components/Tables/SessionTable';
const SessionListPage = () => {
  return (
    <Flex>
      <Container>
        <Head>세션 목록</Head>
        <Margin margin='30px 0 0' />
        <SessionTable />
      </Container>
    </Flex>
  );
};
export default SessionListPage;
