import React from "react";

import { IconPropsWithSVGProps } from "../type";

const RedArrow = (props: IconPropsWithSVGProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M6 18L18 6M6 8V18H16"
      stroke="#FC424A"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default RedArrow;
