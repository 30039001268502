import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Flex, Margin, Span, Container } from '@/styles/style';
import { customFetch } from '@/constants/customFetch';
import { ManageTable } from '@/components/Tables/ManageTable';
import { centerData } from '@/constants/testData';
const ManageCenterPage = () => {
  return (
    <>
      <Container>
        <Head>센터 신청 목록</Head>
        <Margin margin='30px 0 0' />
        <ManageTable />
      </Container>
    </>
  );
};
export default ManageCenterPage;

const Head = styled.div`
  font-size: 18px;
  font-weight: 700;
`;
