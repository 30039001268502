import { Flex, Margin, Head, Container } from '@/styles/style';
import PostTable from '@/components/Tables/PostTable';
const PostListPage = () => {
  return (
    <Flex>
      <Container>
        <Head>게시글 목록</Head>
        <Margin margin='30px 0 0' />
        <PostTable />
      </Container>
    </Flex>
  );
};
export default PostListPage;
