export const allowedImageFormats = '.jpg,.jpeg,.png,.bmp,.tiff,.webp';
export const uploadS3 = async (presignedUrl: string, file: File | Blob) => {
  // const uploadFile = file instanceof File ? await fileToBlob(file) : file;

  const contentType = file.type;
  console.log('upload : ', presignedUrl, file);
  console.log(contentType);

  const response = await fetch(presignedUrl, {
    method: 'PUT',
    body: file,
    headers: {
      'Content-Type': contentType,
    },
  });
  // Status 코드를 통한 추가적인 확인
  if (response.status >= 200 && response.status < 300) {
    console.log('upload successfully');
  } else {
    console.log('upload fail');
    const errorData = await response.text(); // 또는 response.json()
    console.log(`Error data: ${errorData}`);
  }
};
export const timeAgo = (timeString: string) => {
  // 주어진 시간 문자열을 Date 객체로 변환 (시간대 정보를 포함하여)
  const newTime = timeString.split('Z')[0];
  console.log(newTime);
  const givenDate = new Date(newTime);
  // 현재 시간의 Date 객체
  const currentDate = new Date();

  // 두 날짜 사이의 차이(밀리초 단위)
  const difference = currentDate.getTime() - givenDate.getTime();

  // 밀리초를 분, 시간, 일, 월로 변환
  const minutes = Math.floor(difference / (1000 * 60));
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const months = Math.floor(days / 30);

  if (months > 0) return `${months}개월 전`;
  if (days > 0) return `${days}일 전`;
  if (hours > 0) return `${hours}시간 전`;
  if (minutes > 1) return `${minutes}분 전`;
  return `방금 전`;
};

async function fileToBlob(file: File): Promise<Blob> {
  const arrayBuffer = await file.arrayBuffer();
  return new Blob([arrayBuffer], { type: file.type });
}
export function debounce<T>(
  func: (this: T, ...args: any[]) => void,
  timeout: number = 700
): (...args: any[]) => void {
  let timer: NodeJS.Timeout | null = null;
  return function (this: T, ...args: any[]) {
    if (timer !== null) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
}
export const maskEmail = (email: string) => {
  console.log(email);
  if (!email) {
    return;
  }
  const [localPart, domain] = email.split('@');

  if (localPart.length <= 3) {
    return email;
  }

  const visiblePart = localPart.substring(0, 3);
  const maskedPart = '*'.repeat(localPart.length - 3);

  return `${visiblePart}${maskedPart}@${domain}`;
};
export const getRoleDisplayName = (role: string) => {
  switch (role) {
    case 'owner':
      return '관리자';
    case 'staff':
      return '스태프';
    case 'member':
      return '회원';
    case 'guest':
      return '게스트';
    default:
      return ''; // 혹은 '알 수 없음' 등의 기본 값을 지정
  }
};
export const roleOrder: { [key: string]: number } = {
  owner: 1,
  staff: 2,
  member: 3,
  guest: 4,
};

export const truncateText = (inputText: string, maxLength = 30) => {
  if (inputText?.length > maxLength) {
    return `${inputText.substring(0, maxLength)}...`;
  }
  return inputText;
};

//시퀀스 생성기
export const range = (start: number, stop: number, step: number) =>
  Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);
