import styled from 'styled-components';
type ContainerType = {
  height?: string;
};
export const Container = styled.div<ContainerType>`
  width: 100vw;
  height: ${({ height }) => height};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
export const Title = styled.div`
  font-size: 25px;
  font-weight: 700;
  margin-bottom: 40px;
`;
export const ButtonContainer = styled.div`
  width: 260px;
  height: 60px;
  border-radius: 15px;
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: center;
  align-items: center;
  border: 1px solid white;
  cursor: pointer;
`;
export const GoogleImage = styled.img`
  width: 30px;
  height: 30px;
`;
