export const centerData = {
  listCenter: [
    {
      centerId: 'a45e4b8c-5bf8-48da-b1b1-b103fed59989',
      profileImage: {
        accountId: '8a14c66a',
        largeImage:
          'http://thumbor.whistle-pro.com/unsafe/400x400/https://whistlestorage.s3.ap-northeast-2.amazonaws.com/betman.jfif',
        mediumImage:
          'http://thumbor.whistle-pro.com/unsafe/500x500/https://whistlestorage.s3.ap-northeast-2.amazonaws.com/betman.jfif',
        thumbnailImage:
          'http://thumbor.whistle-pro.com/unsafe/300x300/https://whistlestorage.s3.ap-northeast-2.amazonaws.com/betman.jfif',
        originalImage:
          'https://whistlestorage.s3.ap-northeast-2.amazonaws.com/betman.jfif',
      },
      displayName: '센터 테스트11',
      introduce: '센터 소개 소개.. 입니다.',
      location: {
        fullAddress: '센터 주소. 주소 ...',
        latitude: '34.0242420000',
        longitude: '123.0242420000',
      },
      bannerImages: [],
      sportsTags: [
        {
          tagId: 'cdmu0REK47qm',
          tagName: '헬스',
        },
        {
          tagId: '9BkEnpWhFPSG',
          tagName: '건강',
        },
        {
          tagId: 'Nfx9SeEwq3Qo',
          tagName: '강남',
        },
      ],
      isDeleted: false,
      createdAt: '2023-08-03 17:26:16',
      activateState: 'requested',
      centerPhone: '02-131-1241',
      directorName: '대표자 테스트',
      directorPhone: '대표자 연락처',
      regNumber: '12-124-12124',
      centerGrade: 'NEW',
      connRegStatus: '신청중',
      isOpenStatus: '전체회원',
      centerFavor: '0.00',
      centerMemCount: 0,
    },
    {
      centerId: '8c3004ea-f0f4-490a-9f50-1d8e1b6e3967',
      profileImage: {
        accountId: '8a14c66a',
        largeImage:
          'http://thumbor.whistle-pro.com/unsafe/400x400/https://whistlestorage.s3.ap-northeast-2.amazonaws.com/betman.jfif',
        mediumImage:
          'http://thumbor.whistle-pro.com/unsafe/500x500/https://whistlestorage.s3.ap-northeast-2.amazonaws.com/betman.jfif',
        thumbnailImage:
          'http://thumbor.whistle-pro.com/unsafe/300x300/https://whistlestorage.s3.ap-northeast-2.amazonaws.com/betman.jfif',
        originalImage:
          'https://whistlestorage.s3.ap-northeast-2.amazonaws.com/betman.jfif',
      },
      displayName: '센터 테스트2',
      introduce: '센터 소개 소개.. 입니다.',
      location: {
        fullAddress: '센터 주소. 주소 ...',
        latitude: '34.0242420000',
        longitude: '123.0242420000',
      },
      bannerImages: [
        {
          bannerIds: '475e5ca234fd11eeb1b30e7752b2e6d2',
          largeImage:
            'http://thumbor.whistle-pro.com/unsafe/400x400/https://whistlestorage.s3.ap-northeast-2.amazonaws.com/3e692dcb-bfab-402d-b7d2-9c8563bd958a_bannerimages_0.png',
          mediumImage:
            'http://thumbor.whistle-pro.com/unsafe/500x500/https://whistlestorage.s3.ap-northeast-2.amazonaws.com/3e692dcb-bfab-402d-b7d2-9c8563bd958a_bannerimages_0.png',
          thumbnailImage:
            'http://thumbor.whistle-pro.com/unsafe/300x300/https://whistlestorage.s3.ap-northeast-2.amazonaws.com/3e692dcb-bfab-402d-b7d2-9c8563bd958a_bannerimages_0.png',
          originalImage:
            'https://whistlestorage.s3.ap-northeast-2.amazonaws.com/3e692dcb-bfab-402d-b7d2-9c8563bd958a_bannerimages_0.png',
        },
        {
          bannerIds: '47762d2f34fd11eeb1b30e7752b2e6d2',
          largeImage:
            'http://thumbor.whistle-pro.com/unsafe/400x400/https://whistlestorage.s3.ap-northeast-2.amazonaws.com/5204ac94-6a51-4384-80d9-db1fd5b88ba5_bannerimages_1.png',
          mediumImage:
            'http://thumbor.whistle-pro.com/unsafe/500x500/https://whistlestorage.s3.ap-northeast-2.amazonaws.com/5204ac94-6a51-4384-80d9-db1fd5b88ba5_bannerimages_1.png',
          thumbnailImage:
            'http://thumbor.whistle-pro.com/unsafe/300x300/https://whistlestorage.s3.ap-northeast-2.amazonaws.com/5204ac94-6a51-4384-80d9-db1fd5b88ba5_bannerimages_1.png',
          originalImage:
            'https://whistlestorage.s3.ap-northeast-2.amazonaws.com/5204ac94-6a51-4384-80d9-db1fd5b88ba5_bannerimages_1.png',
        },
      ],
      sportsTags: [
        {
          tagId: 'aWIyZgVw2rH4',
          tagName: '헬스',
        },
        {
          tagId: '3nMu1uA5AUvZ',
          tagName: '건강',
        },
        {
          tagId: 'QbxfYtgw0BdU',
          tagName: '강남',
        },
      ],
      isDeleted: false,
      createdAt: '2023-08-07 17:34:52',
      activateState: 'requested',
      centerPhone: '02-131-1241',
      directorName: '대표자 테스트',
      directorPhone: '대표자 연락처',
      regNumber: '12-124-12124',
      centerGrade: 'NEW',
      connRegStatus: '신청중',
      isOpenStatus: '모두',
      centerFavor: '0.00',
      centerMemCount: 0,
    },
  ],
};
export const sportsTagData = {
  sportsTag: [
    {
      tagId: 'TADBpX1dzBA02',
      tagName: '운동',
      largeImage:
        'http://thumbor.whistle-pro.com/unsafe/700*700/https://whistlestorage.s3.ap-northeast-2.amazonaws.com/websiteplanet-qr.png\r\n',
      mediumImage:
        'http://thumbor.whistle-pro.com/unsafe/500*500/https://whistlestorage.s3.ap-northeast-2.amazonaws.com/websiteplanet-qr.png\r\n',
      thumbnailImage:
        'http://thumbor.whistle-pro.com/unsafe/300*300/https://whistlestorage.s3.ap-northeast-2.amazonaws.com/websiteplanet-qr.png\r\n',
      originalImage:
        'http://thumbor.whistle-pro.com/unsafe/300*300/https://whistlestorage.s3.ap-northeast-2.amazonaws.com/websiteplanet-qr.png\r\n',
      deleted: false,
    },
    {
      tagId: 'TADBpX1dzBA01',
      tagName: '스포츠',
      largeImage:
        'http://thumbor.whistle-pro.com/unsafe/700x700/https://whistlestorage.s3.ap-northeast-2.amazonaws.com/spiderman.jfif\r\n',
      mediumImage:
        'http://thumbor.whistle-pro.com/unsafe/500x500/https://whistlestorage.s3.ap-northeast-2.amazonaws.com/spiderman.jfif\r\n',
      thumbnailImage:
        'http://thumbor.whistle-pro.com/unsafe/300x300/https://whistlestorage.s3.ap-northeast-2.amazonaws.com/spiderman.jfif\r\n',
      originalImage:
        'http://thumbor.whistle-pro.com/unsafe/300x300/https://whistlestorage.s3.ap-northeast-2.amazonaws.com/spiderman.jfif\r\n',
      deleted: false,
    },
  ],
};
export const feedbackData = {
  feedbackList: [
    {
      feedbackNo: '0fb5f3ee-c096-4acd-b24c-a066cc1d7aeb',
      userKey: 'IsdfsdfI3121',
      sessionId: '8b4a2198-ccdd-4842-a2f7-0e94bef53f35',
      centerId: '06524786-0109-45d2-b856-9e735a738ae1',
      images: {
        largeImage:
          'http://thumbor.whistle-pro.com/unsafe/400x400/https://whistlefeedback.s3.ap-northeast-2.amazonaws.com/output/8b526ac0-596d-4664-89c0-5ba195a7e043_feedback/Default/Thumbnails/8b526ac0-596d-4664-89c0-5ba195a7e043_feedback.0000000.jpg',
        mediumImage:
          'http://thumbor.whistle-pro.com/unsafe/500x500/https://whistlefeedback.s3.ap-northeast-2.amazonaws.com/output/8b526ac0-596d-4664-89c0-5ba195a7e043_feedback/Default/Thumbnails/8b526ac0-596d-4664-89c0-5ba195a7e043_feedback.0000000.jpg',
        thumbnailImage:
          'http://thumbor.whistle-pro.com/unsafe/300x300/https://whistlefeedback.s3.ap-northeast-2.amazonaws.com/output/8b526ac0-596d-4664-89c0-5ba195a7e043_feedback/Default/Thumbnails/8b526ac0-596d-4664-89c0-5ba195a7e043_feedback.0000000.jpg',
        originalImage:
          'https://whistlefeedback.s3.ap-northeast-2.amazonaws.com/output/8b526ac0-596d-4664-89c0-5ba195a7e043_feedback/Default/Thumbnails/8b526ac0-596d-4664-89c0-5ba195a7e043_feedback.0000000.jpg',
      },
      modifiedAt: '2023-08-19 00:03:58',
      feedbackContent:
        '제 자세는 어떤가요? 이렇게 어깨를 들어 올리는 동작에서 어깨가 등 뒤로 가게 되는데 이건 어떻게 고칠 수 있을까요? 고동을 쓸쓸한 타오르고 스며들어 천지는 같이 역사를 만천하의 속잎나고, 끓는다. 가지에 넣는 그들의 황금시대다. 군영과 피고 인생을 것이다. 오직 꽃이 새가 그들의 황금시대다. 가치를 꽃 싶이 가는 하는 아니한 설레는 뿐이다. ',
      feedbackAlarm: true,
      isVisibility: true,
      timeFit: 2.1,
      showClass: 'F',
      isReverse: true,
      feedbackStatus: 'completed',
      isFeedbackCompleted: true,
      isFeedbackResView: false,
    },
    {
      feedbackNo: '100579f8-5bb5-46d7-8450-47c44fb9bb88',
      userKey: 'IsdfsdfI3121',
      sessionId: 'dc6e9da7-cf82-4625-b155-526027e01a6b',
      centerId: '06524786-0109-45d2-b856-9e735a738ae1',
      images: {
        largeImage:
          'http://thumbor.whistle-pro.com/unsafe/400x400/https://whistlefeedback.s3.ap-northeast-2.amazonaws.com/output/8b526ac0-596d-4664-89c0-5ba195a7e043_feedback/Default/Thumbnails/8b526ac0-596d-4664-89c0-5ba195a7e043_feedback.0000000.jpg',
        mediumImage:
          'http://thumbor.whistle-pro.com/unsafe/500x500/https://whistlefeedback.s3.ap-northeast-2.amazonaws.com/output/8b526ac0-596d-4664-89c0-5ba195a7e043_feedback/Default/Thumbnails/8b526ac0-596d-4664-89c0-5ba195a7e043_feedback.0000000.jpg',
        thumbnailImage:
          'http://thumbor.whistle-pro.com/unsafe/300x300/https://whistlefeedback.s3.ap-northeast-2.amazonaws.com/output/8b526ac0-596d-4664-89c0-5ba195a7e043_feedback/Default/Thumbnails/8b526ac0-596d-4664-89c0-5ba195a7e043_feedback.0000000.jpg',
        originalImage:
          'https://whistlefeedback.s3.ap-northeast-2.amazonaws.com/output/8b526ac0-596d-4664-89c0-5ba195a7e043_feedback/Default/Thumbnails/8b526ac0-596d-4664-89c0-5ba195a7e043_feedback.0000000.jpg',
      },
      feedbackContent:
        '제 자세는 어떤가요? 이렇게 어깨를 들어 올리는 동작에서 어깨가 등 뒤로 가게 되는데 이건 어떻게 고칠 수 있을까요? 고동을 쓸쓸한 타오르고 스며들어 천지는 같이 역사를 만천하의 속잎나고, 끓는다. 가지에 넣는 그들의 황금시대다. 군영과 피고 인생을 것이다. 오직 꽃이 새가 그들의 황금시대다. 가치를 꽃 싶이 가는 하는 아니한 설레는 뿐이다. ',
      feedbackAlarm: true,
      isVisibility: true,
      timeFit: 2.1,
      showClass: 'F',
      isReverse: true,
      feedbackStatus: 'completed',
      isFeedbackCompleted: true,
      feedbackResView: {
        resNo: 3,
        feedbackNo: '100579f8-5bb5-46d7-8450-47c44fb9bb88',
        sessionId: 'dc6e9da7-cf82-4625-b155-526027e01a6b',
        centerId: '06524786-0109-45d2-b856-9e735a738ae1',
        resVodImages: {
          largeImage:
            'http://thumbor.whistle-pro.com/unsafe/400x400/https://whistlefeedback.s3.ap-northeast-2.amazonaws.com/output/8b526ac0-596d-4664-89c0-5ba195a7e043_feedback/Default/Thumbnails/8b526ac0-596d-4664-89c0-5ba195a7e043_feedback.0000000.jpg',
          mediumImage:
            'http://thumbor.whistle-pro.com/unsafe/500x500/https://whistlefeedback.s3.ap-northeast-2.amazonaws.com/output/8b526ac0-596d-4664-89c0-5ba195a7e043_feedback/Default/Thumbnails/8b526ac0-596d-4664-89c0-5ba195a7e043_feedback.0000000.jpg',
          thumbnailImage:
            'http://thumbor.whistle-pro.com/unsafe/300x300/https://whistlefeedback.s3.ap-northeast-2.amazonaws.com/output/8b526ac0-596d-4664-89c0-5ba195a7e043_feedback/Default/Thumbnails/8b526ac0-596d-4664-89c0-5ba195a7e043_feedback.0000000.jpg',
          originalImage:
            'https://whistlefeedback.s3.ap-northeast-2.amazonaws.com/output/8b526ac0-596d-4664-89c0-5ba195a7e043_feedback/Default/Thumbnails/8b526ac0-596d-4664-89c0-5ba195a7e043_feedback.0000000.jpg',
        },
        resUrl:
          'https://whistlefeedback.s3.ap-northeast-2.amazonaws.com/output/fd3946d9-fdae-4d0f-a889-75566851a4e1_feedbackRes/Default/HLS/fd3946d9-fdae-4d0f-a889-75566851a4e1_feedbackRes.m3u8',
        resVodStatus: 'completed',
        isResVodCompleted: true,
        resContent:
          '그거 그렇게 하는거 아닌데. 요래요래 해야 잘되는데 그렇게 하면 다쳐요 회원님. 힘차게 하여도 그들은 보이는 가슴이 그러므로 그들의 보라. 소금이라 굳세게 별과 우리 생명을 있음으로써 사막이다. 사랑의 보내는 그것은 약동하다. 크고 창공에 위하여, 뿐이다.',
      },
      isFeedbackResView: true,
    },
    {
      feedbackNo: '4e0bb9d5-98b0-4a15-8f10-00f62b992975',
      userKey: 'IsdfsdfI3121',
      sessionId: 'f8904c17-eb33-4669-a89d-c65a70f5f491',
      centerId: '06524786-0109-45d2-b856-9e735a738ae1',
      images: {
        largeImage:
          'http://thumbor.whistle-pro.com/unsafe/400x400/https://whistlefeedback.s3.ap-northeast-2.amazonaws.com/output/8b526ac0-596d-4664-89c0-5ba195a7e043_feedback/Default/Thumbnails/8b526ac0-596d-4664-89c0-5ba195a7e043_feedback.0000000.jpg',
        mediumImage:
          'http://thumbor.whistle-pro.com/unsafe/500x500/https://whistlefeedback.s3.ap-northeast-2.amazonaws.com/output/8b526ac0-596d-4664-89c0-5ba195a7e043_feedback/Default/Thumbnails/8b526ac0-596d-4664-89c0-5ba195a7e043_feedback.0000000.jpg',
        thumbnailImage:
          'http://thumbor.whistle-pro.com/unsafe/300x300/https://whistlefeedback.s3.ap-northeast-2.amazonaws.com/output/8b526ac0-596d-4664-89c0-5ba195a7e043_feedback/Default/Thumbnails/8b526ac0-596d-4664-89c0-5ba195a7e043_feedback.0000000.jpg',
        originalImage:
          'https://whistlefeedback.s3.ap-northeast-2.amazonaws.com/output/8b526ac0-596d-4664-89c0-5ba195a7e043_feedback/Default/Thumbnails/8b526ac0-596d-4664-89c0-5ba195a7e043_feedback.0000000.jpg',
      },
      modifiedAt: '2023-08-19 00:01:40',
      feedbackContent:
        '제 자세는 어떤가요? 이렇게 어깨를 들어 올리는 동작에서 어깨가 등 뒤로 가게 되는데 이건 어떻게 고칠 수 있을까요? 고동을 쓸쓸한 타오르고 스며들어 천지는 같이 역사를 만천하의 속잎나고, 끓는다. 가지에 넣는 그들의 황금시대다. 군영과 피고 인생을 것이다. 오직 꽃이 새가 그들의 황금시대다. 가치를 꽃 싶이 가는 하는 아니한 설레는 뿐이다. ',
      feedbackAlarm: true,
      isVisibility: true,
      timeFit: 2.1,
      showClass: 'F',
      isReverse: true,
      feedbackStatus: 'completed',
      isFeedbackCompleted: true,
      isFeedbackResView: false,
    },
  ],
};
export const sessionData = {
  listInfo: {
    limit: 10,
    offset: 10,
    totalCount: 24,
    nextOffset: 10,
    isLastOffset: false,
  },
  listCenter: [
    {
      centerId: 'b9804fdd-8472-4e90-8716-5acd69110dcb',
      displayName: '센터 테스트11',
      introduce: '센터 소개 소개.. 입니다.',
      location: {
        fullAddress: '서울시 강남구 | 대치동  12',
        latitude: '34.0242420000',
        longitude: '123.0242420000',
      },
      bannerImages: [
        {
          bannerIds: '09a9355331be11eeb1b30e7752b2e6d2',
          largeImage:
            'http://thumbor.whistle-pro.com/unsafe/400x400/bannerImage url 1',
          mediumImage:
            'http://thumbor.whistle-pro.com/unsafe/500x500/bannerImage url 1',
          thumbnailImage:
            'http://thumbor.whistle-pro.com/unsafe/300x300/bannerImage url 1',
          originalImage: 'bannerImage url 1',
        },
        {
          bannerIds: '09aa557531be11eeb1b30e7752b2e6d2',
          largeImage:
            'http://thumbor.whistle-pro.com/unsafe/400x400/bannerImage url 2',
          mediumImage:
            'http://thumbor.whistle-pro.com/unsafe/500x500/bannerImage url 2',
          thumbnailImage:
            'http://thumbor.whistle-pro.com/unsafe/300x300/bannerImage url 2',
          originalImage: 'bannerImage url 2',
        },
        {
          bannerIds: '09ab8c4131be11eeb1b30e7752b2e6d2',
          largeImage:
            'http://thumbor.whistle-pro.com/unsafe/400x400/bannerImage url 3',
          mediumImage:
            'http://thumbor.whistle-pro.com/unsafe/500x500/bannerImage url 3',
          thumbnailImage:
            'http://thumbor.whistle-pro.com/unsafe/300x300/bannerImage url 3',
          originalImage: 'bannerImage url 3',
        },
        {
          bannerIds: '09ad6f8631be11eeb1b30e7752b2e6d2',
          largeImage:
            'http://thumbor.whistle-pro.com/unsafe/400x400/bannerImage url 4',
          mediumImage:
            'http://thumbor.whistle-pro.com/unsafe/500x500/bannerImage url 4',
          thumbnailImage:
            'http://thumbor.whistle-pro.com/unsafe/300x300/bannerImage url 4',
          originalImage: 'bannerImage url 4',
        },
        {
          bannerIds: '09ae8a7a31be11eeb1b30e7752b2e6d2',
          largeImage:
            'http://thumbor.whistle-pro.com/unsafe/400x400/bannerImage url 5',
          mediumImage:
            'http://thumbor.whistle-pro.com/unsafe/500x500/bannerImage url 5',
          thumbnailImage:
            'http://thumbor.whistle-pro.com/unsafe/300x300/bannerImage url 5',
          originalImage: 'bannerImage url 5',
        },
        {
          bannerIds: '09afaefa31be11eeb1b30e7752b2e6d2',
          largeImage:
            'http://thumbor.whistle-pro.com/unsafe/400x400/bannerImage url 6',
          mediumImage:
            'http://thumbor.whistle-pro.com/unsafe/500x500/bannerImage url 6',
          thumbnailImage:
            'http://thumbor.whistle-pro.com/unsafe/300x300/bannerImage url 6',
          originalImage: 'bannerImage url 6',
        },
        {
          bannerIds: '09b0cd6c31be11eeb1b30e7752b2e6d2',
          largeImage:
            'http://thumbor.whistle-pro.com/unsafe/400x400/bannerImage url 7',
          mediumImage:
            'http://thumbor.whistle-pro.com/unsafe/500x500/bannerImage url 7',
          thumbnailImage:
            'http://thumbor.whistle-pro.com/unsafe/300x300/bannerImage url 7',
          originalImage: 'bannerImage url 7',
        },
        {
          bannerIds: '09b1d10f31be11eeb1b30e7752b2e6d2',
          largeImage:
            'http://thumbor.whistle-pro.com/unsafe/400x400/bannerImage url 8',
          mediumImage:
            'http://thumbor.whistle-pro.com/unsafe/500x500/bannerImage url 8',
          thumbnailImage:
            'http://thumbor.whistle-pro.com/unsafe/300x300/bannerImage url 8',
          originalImage: 'bannerImage url 8',
        },
        {
          bannerIds: '09b2c52631be11eeb1b30e7752b2e6d2',
          largeImage:
            'http://thumbor.whistle-pro.com/unsafe/400x400/bannerImage url 9',
          mediumImage:
            'http://thumbor.whistle-pro.com/unsafe/500x500/bannerImage url 9',
          thumbnailImage:
            'http://thumbor.whistle-pro.com/unsafe/300x300/bannerImage url 9',
          originalImage: 'bannerImage url 9',
        },
        {
          bannerIds: '09b3cb1931be11eeb1b30e7752b2e6d2',
          largeImage:
            'http://thumbor.whistle-pro.com/unsafe/400x400/bannerImage url 10',
          mediumImage:
            'http://thumbor.whistle-pro.com/unsafe/500x500/bannerImage url 10',
          thumbnailImage:
            'http://thumbor.whistle-pro.com/unsafe/300x300/bannerImage url 10',
          originalImage: 'bannerImage url 10',
        },
      ],
      sportsTags: [],
      isDeleted: false,
      createdAt: '2023-08-03 14:24:37',
      activateState: 'requested',
      centerPhone: '02-131-1241',
      directorName: '대표자 테스트',
      directorPhone: '대표자 연락처',
      regNumber: '12-124-12124',
      businessRegImage: 'http://url2',
      centerGrade: 'NEW',
      connRegStatus: '신청중',
      isOpenStatus: '전체회원',
      centerFavor: '0.00',
      centerMemCount: 0,
    },
  ],
};
export const postData = {
  list: [
    {
      postId: '0c4dc4cb-95a8-4435-b9dd-6446c293d853',
      centerId: 'e5997dcc-ab40-4af5-b79a-eb435d65e4c7',
      centerName: '센터 테스트 22',
      content:
        '1. 약 400여평 강남역 인근 최고 시설로 오픈한 스포애니 강남역1호점은 헬스, 요가, 다이어트댄스, 점핑다이어트 등 각종 G.X를 갖춘 대규모 종합 피트니스센터 입니다. 2. 신세대 기호에 맞춘 젊고 세련된 감각의 최신식 인테리어로 오픈하였습니다. (2022년 9월1일 기구, 런닝머신 전체교체 완료하여 쾌적한 기구와 런닝머신을 자랑합니다) 3. 약 70여종의 유산소기구, 50여종의 최고급 운동시설이 준비되어 있습니다. * 웨이트머신 브랜드 : 국내 최고급 뉴텍 풀세트  4. 넓고 쾌적한 개인 샤워시설을 제공합니다 ',
      userKey: 'IsdfsdfI3121',
      isDeleted: false,
      createAt: '2023-08-11 10:24:00',
      isNoti: 'true',
      greatCount: 0,
      replyCount: 0,
      isLikeExist: false,
      isMyPost: false,
      isLikeData: false,
      isBoardCompleted: 0,
      profileImageBoard: {
        largeImage:
          'http://thumbor.whistle-pro.com/unsafe/400x400/https://whistlestorage.s3.ap-northeast-2.amazonaws.com/profileImage/profileImage4.jpg',
        mediumImage:
          'http://thumbor.whistle-pro.com/unsafe/500x500/https://whistlestorage.s3.ap-northeast-2.amazonaws.com/profileImage/profileImage4.jpg',
        thumbnailImage:
          'http://thumbor.whistle-pro.com/unsafe/300x300/https://whistlestorage.s3.ap-northeast-2.amazonaws.com/profileImage/profileImage4.jpg',
        originalImage: 'profileImage6.jpg',
        nickName: 'jitaesoup99@whistle-pro.com 닉네임 테스트 2',
      },
    },
    {
      postId: '0e3b6835-08f2-4211-bc20-756f89e3ec3a',
      centerId: 'e5997dcc-ab40-4af5-b79a-eb435d65e4c7',
      centerName: '센터 테스트 22',
      content: '테스트 5',
      userKey: 'sfasfasf9wq',
      isDeleted: false,
      createAt: '2023-07-26 21:09:49',
      isNoti: 'false',
      greatCount: 0,
      replyCount: 3,
      isLikeExist: false,
      isMyPost: false,
      isLikeData: false,
      isBoardCompleted: 0,
      profileImageBoard: {
        largeImage:
          'http://thumbor.whistle-pro.com/unsafe/400x400/https://whistlestorage.s3.ap-northeast-2.amazonaws.com/profileImage/profileImage6.jpg',
        mediumImage:
          'http://thumbor.whistle-pro.com/unsafe/500x500/https://whistlestorage.s3.ap-northeast-2.amazonaws.com/profileImage/profileImage6.jpg',
        thumbnailImage:
          'http://thumbor.whistle-pro.com/unsafe/300x300/https://whistlestorage.s3.ap-northeast-2.amazonaws.com/profileImage/profileImage6.jpg',
        originalImage: 'profileImage6.jpg',
        nickName: 'kittyhawk99@ 닉네임 테스트 444',
      },
    },
    {
      postId: '0e3c534a-f68d-4ace-9def-867ea15cb494',
      centerId: 'e5997dcc-ab40-4af5-b79a-eb435d65e4c7',
      centerName: '센터 테스트 22',
      content:
        '1. 약 400여평 강남역 인근 최고 시설로 오픈한 스포애니 강남역1호점은 헬스, 요가, 다이어트댄스, 점핑다이어트 등 각종 G.X를 갖춘 대규모 종합 피트니스센터 입니다. 2. 신세대 기호에 맞춘 젊고 세련된 감각의 최신식 인테리어로 오픈하였습니다. (2022년 9월1일 기구, 런닝머신 전체교체 완료하여 쾌적한 기구와 런닝머신을 자랑합니다) 3. 약 70여종의 유산소기구, 50여종의 최고급 운동시설이 준비되어 있습니다. * 웨이트머신 브랜드 : 국내 최고급 뉴텍 풀세트  4. 넓고 쾌적한 개인 샤워시설을 제공합니다 ',
      userKey: '8a14c66a',
      isDeleted: false,
      createAt: '2023-08-17 02:06:03',
      isNoti: 'false',
      greatCount: 0,
      replyCount: 0,
      isLikeExist: false,
      isMyPost: false,
      isLikeData: false,
      isBoardCompleted: 0,
      profileImageBoard: {
        largeImage:
          'http://thumbor.whistle-pro.com/unsafe/400x400/https://whistlestorage.s3.ap-northeast-2.amazonaws.com/profileImage/profileImage4.jpg',
        mediumImage:
          'http://thumbor.whistle-pro.com/unsafe/500x500/https://whistlestorage.s3.ap-northeast-2.amazonaws.com/profileImage/profileImage4.jpg',
        thumbnailImage:
          'http://thumbor.whistle-pro.com/unsafe/300x300/https://whistlestorage.s3.ap-northeast-2.amazonaws.com/profileImage/profileImage4.jpg',
        originalImage: 'profileImage6.jpg',
        nickName: 'kittyhawk99@ 닉네임 테스트 222',
      },
    },
    {
      postId: '14824c2e-dffd-4301-b522-81a3c674cc8e',
      centerId: 'e5997dcc-ab40-4af5-b79a-eb435d65e4c7',
      centerName: '센터 테스트 22',
      content: '테스트 댓글 2 내용',
      userKey: 'IsdfsdfI3121',
      isDeleted: false,
      createAt: '2023-07-02 20:03:56',
      isNoti: 'false',
      greatCount: 0,
      replyCount: 0,
      isLikeExist: false,
      isMyPost: false,
      isLikeData: false,
      isBoardCompleted: 0,
      profileImageBoard: {
        largeImage:
          'http://thumbor.whistle-pro.com/unsafe/400x400/https://whistlestorage.s3.ap-northeast-2.amazonaws.com/profileImage/profileImage4.jpg',
        mediumImage:
          'http://thumbor.whistle-pro.com/unsafe/500x500/https://whistlestorage.s3.ap-northeast-2.amazonaws.com/profileImage/profileImage4.jpg',
        thumbnailImage:
          'http://thumbor.whistle-pro.com/unsafe/300x300/https://whistlestorage.s3.ap-northeast-2.amazonaws.com/profileImage/profileImage4.jpg',
        originalImage: 'profileImage6.jpg',
        nickName: 'jitaesoup99@whistle-pro.com 닉네임 테스트 2',
      },
    },
    {
      postId: '78a129ee-8442-40ae-92c2-cd68141ed36e',
      centerId: 'e5997dcc-ab40-4af5-b79a-eb435d65e4c7',
      centerName: '센터 테스트 22',
      content: '테스트 댓글 1 내용',
      userKey: 'IsdfsdfI3121',
      isDeleted: false,
      createAt: '2023-07-02 18:13:06',
      isNoti: 'false',
      greatCount: 0,
      replyCount: 0,
      isLikeExist: false,
      isMyPost: false,
      isLikeData: false,
      isBoardCompleted: 0,
      profileImageBoard: {
        largeImage:
          'http://thumbor.whistle-pro.com/unsafe/400x400/https://whistlestorage.s3.ap-northeast-2.amazonaws.com/profileImage/profileImage4.jpg',
        mediumImage:
          'http://thumbor.whistle-pro.com/unsafe/500x500/https://whistlestorage.s3.ap-northeast-2.amazonaws.com/profileImage/profileImage4.jpg',
        thumbnailImage:
          'http://thumbor.whistle-pro.com/unsafe/300x300/https://whistlestorage.s3.ap-northeast-2.amazonaws.com/profileImage/profileImage4.jpg',
        originalImage: 'profileImage6.jpg',
        nickName: 'jitaesoup99@whistle-pro.com 닉네임 테스트 2',
      },
    },
    {
      postId: '8dcfdb44-2488-4499-a15a-2dfc7f4b8df0',
      centerId: 'e5997dcc-ab40-4af5-b79a-eb435d65e4c7',
      centerName: '센터 테스트 22',
      content: '테스트 999 - 댓글',
      userKey: '8a14c66a',
      isDeleted: false,
      createAt: '2023-07-28 17:51:00',
      isNoti: 'false',
      greatCount: 0,
      replyCount: 0,
      isLikeExist: false,
      isMyPost: false,
      isLikeData: false,
      isBoardCompleted: 0,
      profileImageBoard: {
        largeImage:
          'http://thumbor.whistle-pro.com/unsafe/400x400/https://whistlestorage.s3.ap-northeast-2.amazonaws.com/profileImage/profileImage4.jpg',
        mediumImage:
          'http://thumbor.whistle-pro.com/unsafe/500x500/https://whistlestorage.s3.ap-northeast-2.amazonaws.com/profileImage/profileImage4.jpg',
        thumbnailImage:
          'http://thumbor.whistle-pro.com/unsafe/300x300/https://whistlestorage.s3.ap-northeast-2.amazonaws.com/profileImage/profileImage4.jpg',
        originalImage: 'profileImage6.jpg',
        nickName: 'kittyhawk99@ 닉네임 테스트 222',
      },
    },
    {
      postId: '93a01408-4444-4234-b73e-a63ad0c77908',
      centerId: 'e5997dcc-ab40-4af5-b79a-eb435d65e4c7',
      centerName: '센터 테스트 22',
      content: '테스트 5 - 댓글 1',
      userKey: 'qrqwr9124',
      isDeleted: true,
      createAt: '2023-08-09 03:50:40',
      isNoti: 'false',
      greatCount: 0,
      replyCount: 0,
      isLikeExist: false,
      isMyPost: false,
      isLikeData: false,
      isBoardCompleted: 0,
      profileImageBoard: {
        nickName: '1122wsip',
      },
    },
    {
      postId: '93a01408-aa44-4234-b73e-a63ad0c77908',
      centerId: 'e5997dcc-ab40-4af5-b79a-eb435d65e4c7',
      centerName: '센터 테스트 22',
      content: '테스트 5 - 댓글 2',
      userKey: 'asqwr9124',
      isDeleted: false,
      createAt: '2023-08-09 03:51:40',
      isNoti: 'false',
      greatCount: 0,
      replyCount: 0,
      isLikeExist: false,
      isMyPost: false,
      isLikeData: false,
      isBoardCompleted: 0,
      profileImageBoard: {
        nickName: 'jump0423',
      },
    },
    {
      postId: '93a01408-aegc-4234-b73e-a63ad0c77908',
      centerId: 'e5997dcc-ab40-4af5-b79a-eb435d65e4c7',
      centerName: '센터 테스트 22',
      content: '테스트 5 - 댓글 3',
      userKey: 'IsdfsdfI3124',
      isDeleted: false,
      createAt: '2023-08-09 03:52:40',
      isNoti: 'false',
      greatCount: 0,
      replyCount: 0,
      isLikeExist: false,
      isMyPost: false,
      isLikeData: false,
      isBoardCompleted: 0,
      profileImageBoard: {
        nickName: 'aaa',
      },
    },
    {
      postId: '9aac8d10-cd1e-48d0-97a7-cab36845ec76',
      centerId: 'e5997dcc-ab40-4af5-b79a-eb435d65e4c7',
      centerName: '센터 테스트 22',
      content: 'kittyhawk9 - 커뮤니티 글에 댓글 테스트 추가 3 444',
      userKey: '8a14c66a',
      isDeleted: false,
      createAt: '2023-08-11 11:24:26',
      isNoti: 'false',
      greatCount: 0,
      replyCount: 0,
      isLikeExist: false,
      isMyPost: false,
      isLikeData: false,
      isBoardCompleted: 0,
      profileImageBoard: {
        largeImage:
          'http://thumbor.whistle-pro.com/unsafe/400x400/https://whistlestorage.s3.ap-northeast-2.amazonaws.com/profileImage/profileImage4.jpg',
        mediumImage:
          'http://thumbor.whistle-pro.com/unsafe/500x500/https://whistlestorage.s3.ap-northeast-2.amazonaws.com/profileImage/profileImage4.jpg',
        thumbnailImage:
          'http://thumbor.whistle-pro.com/unsafe/300x300/https://whistlestorage.s3.ap-northeast-2.amazonaws.com/profileImage/profileImage4.jpg',
        originalImage: 'profileImage6.jpg',
        nickName: 'kittyhawk99@ 닉네임 테스트 222',
      },
    },
  ],
  listInfo: {
    limit: 10,
    offset: 10,
    totalCount: 24,
    nextOffset: 10,
    isLastOffset: false,
  },
};
export const userList = {
  userlist: [
    {
      email: 'jitaesoup99@whistle-pro.co.kr',
      accountId: '912412qrqwr',
      centerId: 'abf9a6a6-4a0f-40aa-81e2-3bde755c3082',
      profileImage: {
        largeImage:
          'http://thumbor.whistle-pro.com/unsafe/400x400/https://whistlestorage.s3.ap-northeast-2.amazonaws.com/user_3.png',
        mediumImage:
          'http://thumbor.whistle-pro.com/unsafe/500x500/https://whistlestorage.s3.ap-northeast-2.amazonaws.com/user_3.png',
        thumbnailImage:
          'http://thumbor.whistle-pro.com/unsafe/300x300/https://whistlestorage.s3.ap-northeast-2.amazonaws.com/user_3.png',
        originalImage:
          'https://whistlestorage.s3.ap-northeast-2.amazonaws.com/user_3.png',
      },
      nickname: '사용자 닉넥임2',
      role: '스탭',
      permissionState: 'activated',
      userKey: '912412qrqwr',
      connRegStatusCode: '00',
      connRegDate: '2023-08-21 02:51:34',
      connRegStatus: 'requested',
    },
    {
      email: 'jitaesoup99@whistle-pro.com',
      accountId: 'IsdfsdfI3121',
      centerId: 'abf9a6a6-4a0f-40aa-81e2-3bde755c3082',
      profileImage: {
        largeImage:
          'http://thumbor.whistle-pro.com/unsafe/400x400/https://whistlestorage.s3.ap-northeast-2.amazonaws.com/user_3.png',
        mediumImage:
          'http://thumbor.whistle-pro.com/unsafe/500x500/https://whistlestorage.s3.ap-northeast-2.amazonaws.com/user_3.png',
        thumbnailImage:
          'http://thumbor.whistle-pro.com/unsafe/300x300/https://whistlestorage.s3.ap-northeast-2.amazonaws.com/user_3.png',
        originalImage:
          'https://whistlestorage.s3.ap-northeast-2.amazonaws.com/user_3.png',
      },
      nickname: 'jitaessoup 닉네임 닉네임',
      role: '스탭',
      permissionState: 'activated',
      userKey: 'IsdfsdfI3121',
      connRegStatusCode: '01',
      connRegDate: '2023-07-09 14:40:00',
      connRegStatus: 'activated',
    },
  ],
  listInfo: {
    limit: 10,
    offset: 0,
    totalCount: 2,
    nextOffset: 0,
  },
};
