import { GoogleAuthProvider, signInWithPopup } from '@firebase/auth';

import { useEffect, useContext, useState } from 'react';
import { AuthContext } from '../../context/authContext';
import { useNavigate } from 'react-router-dom';
import { auth } from '../../firebase';
import {
  ButtonContainer,
  Container,
  GoogleImage,
  Title,
} from '@/styles/LoginStyles';
import { useAuthCheckStore } from '../../store/auth';

function LoginForm() {
  const navigate = useNavigate();
  const { authCheck, setAuthCheck } = useAuthCheckStore();
  useEffect(() => {
    if (authCheck) {
      navigate('/admin/user/list');
    }
  }, [authCheck]);
  const googleLogin = async () => {
    const googleAuthProvider = new GoogleAuthProvider();
    signInWithPopup(auth, googleAuthProvider)
      .then((result) => {
        const email = result.user.email;
        const domain = email?.substring(email.lastIndexOf('@') + 1);
        if (domain !== 'whistle-pro.com') {
          auth.signOut();
          alert('관리자 게정이 아닙니다.');
        } else {
          setAuthCheck(true);
        }
      })
      .catch((error) => {});
  };
  return (
    <Container height='100vh'>
      <Title>WhistlePro Admin</Title>
      <ButtonContainer onClick={googleLogin}>
        <GoogleImage src='/google.png' />
        <div>구글 로그인</div>
      </ButtonContainer>
    </Container>
  );
}
export default LoginForm;
