import React from 'react';

import { IconPropsWithSVGProps } from '../type';

const ThumbsUp = (props: IconPropsWithSVGProps) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
  >
    <path
      d='M1.33337 8.33339C1.33337 7.59701 1.93033 7.00006 2.66671 7.00006C3.77128 7.00006 4.66671 7.89549 4.66671 9.00006V11.6667C4.66671 12.7713 3.77128 13.6667 2.66671 13.6667C1.93033 13.6667 1.33337 13.0698 1.33337 12.3334V8.33339Z'
      stroke='#D3D4DC'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M10.3192 5.20424L10.1417 5.77763C9.99623 6.24748 9.9235 6.4824 9.97943 6.66794C10.0247 6.81804 10.124 6.94742 10.2594 7.03254C10.4268 7.13777 10.6799 7.13777 11.1862 7.13777H11.4555C13.1689 7.13777 14.0255 7.13777 14.4302 7.64494C14.4764 7.7029 14.5176 7.76453 14.5531 7.82918C14.8644 8.39484 14.5105 9.15683 13.8027 10.6808C13.1532 12.0793 12.8284 12.7786 12.2254 13.1902C12.1671 13.23 12.1071 13.2676 12.0456 13.3028C11.4108 13.6667 10.6242 13.6667 9.05102 13.6667H8.70981C6.80389 13.6667 5.85093 13.6667 5.25884 13.0931C4.66675 12.5194 4.66675 11.596 4.66675 9.74936V9.1003C4.66675 8.12983 4.66675 7.64459 4.83898 7.20047C5.0112 6.75634 5.34098 6.39117 6.00054 5.66081L8.72816 2.64044C8.79656 2.5647 8.83077 2.52682 8.86093 2.50057C9.14244 2.25559 9.5769 2.28317 9.82302 2.56164C9.84939 2.59147 9.8782 2.63335 9.93582 2.7171C10.026 2.8481 10.071 2.91361 10.1103 2.97851C10.462 3.55949 10.5684 4.24965 10.4073 4.90484C10.3893 4.97802 10.3659 5.05347 10.3192 5.20424Z'
      stroke='#D3D4DC'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export default ThumbsUp;
