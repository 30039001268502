import styled from 'styled-components';
interface FlexProps {
  width?: string;
  maxWidth?: string;
  height?: string;
  position?: string;
  flex?: number;
  flexDirection?: string;
  margin?: string;
  gap?: string;
  alignItems?: string;
  justifyContents?: string;
  padding?: string;
  border?: string;
  borderRadius?: string;
  overflow?: string;
  flexWrap?: string;
  childFlex?: number;
}
export const Flex = styled.div<FlexProps>`
  width: ${({ width }) => width};
  max-width: ${({ maxWidth }) => maxWidth};
  height: ${({ height }) => height};
  position: ${({ position }) => position};
  display: flex;
  flex: ${({ flex }) => flex};
  padding: ${({ padding }) => padding};
  flex-direction: ${({ flexDirection }) => flexDirection ?? 'row'};
  margin: ${({ margin }) => margin};
  gap: ${({ gap }) => gap};
  align-items: ${({ alignItems }) => alignItems};
  justify-content: ${({ justifyContents }) => justifyContents};
  border: ${({ border }) => border};
  border-radius: ${({ borderRadius }) => borderRadius};
  overflow: ${({ overflow }) => overflow};
  flex-wrap: ${({ flexWrap }) => flexWrap};
  > div {
    flex: ${({ childFlex }) => childFlex};
  }
`;

export const GrayButton = styled.div`
  width: 100%;
  color: white;
  font-size: 13px;
  text-align: center;
  border-radius: 3px;
  background-color: var(--lightgray);
  height: 35px;
  line-height: 32px;
  cursor: pointer;

  :hover {
    background-color: var(--gray);
  }
`;
type HrType = {
  backgroundColor?: string;
  margin?: string;
};
export const Hr = styled.div<HrType>`
  width: 100%;
  height: 1px;
  margin: ${({ margin }) => margin};
  background-color: ${({ backgroundColor }) => backgroundColor};
`;
type ButtonProps = {
  border?: string;
  backgroundColor?: string;
  color?: string;
  fontWeight?: string;
};
export const Button = styled.button<ButtonProps>`
  height: 30px;
  text-align: center;
  padding: 0 15px;
  font-size: 13px;
  border-radius: 4px;
  color: ${({ color }) => color || 'white'};
  background-color: ${({ backgroundColor }) => backgroundColor};
  border: ${({ border }) => border || '1px solid #d7dadb'};
  font-weight: ${({ fontWeight }) => fontWeight};
  cursor: pointer;
  :hover {
    box-shadow: rgb(63 71 77 / 20%) 4px 4px;
  }
`;
export const Input = styled.input`
  width: 100%;
  height: 40px;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  font-size: 15px;
`;
interface SpanProps {
  fontSize?: string;
  fontWeight?: string;
  color?: string;
  padding?: string;
  margin?: string;
  cursor?: string;
  textDecoration?: string;
  lineHeight?: string;
  whiteSpace?: string;
}
export const Span = styled.span<SpanProps>`
  font-size: ${({ fontSize }) => fontSize};
  font-weight: ${({ fontWeight }) => fontWeight};
  color: ${({ color }) => color};
  padding: ${({ padding }) => padding};
  margin: ${({ margin }) => margin};
  line-height: ${({ lineHeight }) => lineHeight};
  text-decoration: ${({ textDecoration }) => textDecoration};
  white-space: ${({ whiteSpace }) => whiteSpace};
  cursor: ${({ cursor }) => cursor};
`;
type MarginType = {
  margin: string;
};
export const Margin = styled.div<MarginType>`
  margin: ${({ margin }) => margin};
`;
export const PremiumBadge = styled.div`
  width: 52px;
  height: 21px;
  border-radius: 6.5px;
  font-weight: bold;
  font-size: 10px;
  letter-spacing: -0.5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--primary);
  color: black;
`;
type CircleImageType = {
  size?: string;
};
export const CircleImage = styled.div<CircleImageType>`
  position: relative;
  width: ${({ size }) => size || '35px'};
  height: ${({ size }) => size || '35px'};
  border-radius: 100%;
  flex-shrink: 0;
  overflow: hidden;
  > img {
    width: 100%;
    height: 100%;
  }
`;

export const PreLineDiv = styled.div`
  white-space: pre-line;
  line-height: 18px;
`;
export const NullDataBox = styled.div`
  padding: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  color: var(--c500);
`;
export const Container = styled.div`
  width: 100%;
  max-width: 1200px;
  min-width: 650px;
  background-color: #24252d;
  padding: 32px;
  font-size: 1rem;
  position: relative;
  margin: 0 auto;
  cursor: default;
  overflow: auto;
`;
export const SelectWrapper = styled.div`
  width: 80px;
  border: 1px solid white;
  border-radius: 1px;
`;
export const Select = styled.select`
  width: 100%;
  padding: 5px;
  background-color: var(--background-color);
  /* border-right: 15px solid transparent; */
  color: white;
  font-weight: bold;
  cursor: pointer;
`;
export const Option = styled.option`
  padding: 5px;
  background-color: var(--background-color);
  :hover {
    background-color: black;
  }
`;
export const Head = styled.div`
  font-size: 18px;
  font-weight: 700;
`;
export const PointerDiv = styled.div`
  cursor: pointer;
`;

export const CheckBoxContainer = styled.div`
  display: flex;
  gap: 7px;
  align-items: center;
  cursor: pointer;
  > * {
    cursor: pointer;
  }
`;

export const SearchInput = styled.input`
  border-radius: 7px;
  border: 1px solid var(--c300);
  background-color: transparent;
  height: 30px;
  padding: 0 10px;
  color: white;
`;
