import React from "react";

import { IconPropsWithSVGProps } from "../type";

const DashBoard = (props: IconPropsWithSVGProps) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 11.8012C8.40326 11.8012 7.83097 11.569 7.40901 11.1557C6.98705 10.7423 6.75 10.1817 6.75 9.59711C6.75 8.77423 7.2075 8.05422 7.875 7.67951L15.1575 3.55044L11.01 10.589C10.635 11.309 9.8775 11.8012 9 11.8012ZM9 2.25C10.3575 2.25 11.625 2.61736 12.7275 3.21982L11.1525 4.10882C10.5 3.85902 9.75 3.71942 9 3.71942C7.4087 3.71942 5.88258 4.33868 4.75736 5.44096C3.63214 6.54324 3 8.03825 3 9.59711C3 11.2208 3.6675 12.6902 4.755 13.7482H4.7625C5.055 14.0348 5.055 14.4976 4.7625 14.7842C4.47 15.0707 3.99 15.0707 3.6975 14.7915C2.99998 14.1102 2.44677 13.3005 2.06966 12.4091C1.69254 11.5177 1.49895 10.5621 1.5 9.59711C1.5 7.64854 2.29018 5.77977 3.6967 4.40192C5.10322 3.02407 7.01088 2.25 9 2.25ZM16.5 9.59711C16.5 11.6249 15.66 13.4617 14.3025 14.7915C14.01 15.0707 13.5375 15.0707 13.245 14.7842C13.1755 14.7162 13.1203 14.6355 13.0827 14.5466C13.045 14.4577 13.0257 14.3624 13.0257 14.2662C13.0257 14.17 13.045 14.0747 13.0827 13.9858C13.1203 13.8969 13.1755 13.8162 13.245 13.7482C13.8028 13.2041 14.245 12.5571 14.5462 11.8446C14.8475 11.1321 15.0017 10.3683 15 9.59711C15 8.8624 14.8575 8.12769 14.595 7.46645L15.5025 5.92356C16.125 7.02562 16.5 8.25994 16.5 9.59711Z"
      fill="white"
    />
  </svg>
);

export default DashBoard;
