import {
  Flex,
  CircleImage,
  NullDataBox,
  Span,
  SelectWrapper,
  Select,
  Option,
  SearchInput,
} from '@/styles/style';
import * as T from '@/styles/TableStyle';
import { getRoleDisplayName } from '@/constants/common';
import { ProfileType } from '@/constants/type';
import { useState } from 'react';
import { TableType } from '../Tables/type';
import { AccountDetailModal } from '../Modals/AccountDetailModal';
import useCustomTable from '@/hooks/useCustomTable';
import { selectItem } from '../Tables/common';
import Pagination from '../Pagination';

export const AccountTable = ({
  selectedItemList,
  setSelectedItemList,
  showOnlySelected,
  canCheck,
  canSearch,
  keyword,
  setKeyword,
}: TableType) => {
  const [currentPagination, setCurrentPagination] = useState<number>(1);
  const [orderBy, setOrderBy] = useState<'desc' | 'asc'>('desc');
  const [role, setRole] = useState<'all' | 'staff' | 'member' | 'owner'>('all');
  const [modalShow, setModalShow] = useState<boolean>(false);
  const [selectedData, setSelectedData] = useState<ProfileType>();

  const { list, setList, listInfo, offset, setOffset } = useCustomTable({
    itemType: 'account',
    showOnlySelected,
    selectedItemList,
    currentPagination,
    setCurrentPagination,
    orderBy,
    role,
    keyword,
    setKeyword,
  });
  return (
    <>
      <Flex justifyContents='space-between'>
        <Span color='var(--c100)'>
          총{' '}
          {showOnlySelected ? selectedItemList?.length : listInfo?.totalCount}명
        </Span>
      </Flex>
      {canSearch && (
        <SearchInput
          placeholder='대상을 검색해주세요'
          onChange={(e) => setKeyword(e.target.value.trim())}
        />
      )}
      <T.Container>
        <T.TableContainer>
          <T.Table>
            <T.THead>
              <T.Tr>
                {canCheck && <T.Th width='50px'>선택</T.Th>}
                <T.Th width='50px' textAlign='center'>
                  번호
                </T.Th>
                <T.Th>이름</T.Th>
                <T.Th>이메일</T.Th>
                <T.Th>연락처</T.Th>
                <T.Th>가입일</T.Th>
              </T.Tr>
            </T.THead>
            <T.TBody>
              {list.length > 0 ? (
                list.map((item, index) => {
                  return (
                    <T.Tr
                      key={index}
                      onClick={() => {
                        if (!canCheck) {
                          setModalShow(true);
                          setSelectedData(item);
                        }
                      }}
                    >
                      {canCheck && selectedItemList && setSelectedItemList && (
                        <T.Td>
                          <input
                            type='checkbox'
                            checked={selectedItemList.some(
                              (selectedItem) =>
                                selectedItem.userKey === item.userKey
                            )}
                            onChange={() =>
                              selectItem(
                                item,
                                selectedItemList,
                                setSelectedItemList
                              )
                            }
                          />
                        </T.Td>
                      )}
                      <T.Td textAlign='center'>{offset + index + 1}</T.Td>
                      <T.Td>{item.userName}</T.Td>
                      <T.Td>{item.email}</T.Td>
                      <T.Td>{item.phoneNumber}</T.Td>
                      <T.Td>{item.createdAt?.split('T')[0]}</T.Td>
                    </T.Tr>
                  );
                })
              ) : (
                <T.Tr>
                  <T.Td colSpan={5}>
                    <NullDataBox>데이터가 없습니다</NullDataBox>
                  </T.Td>
                </T.Tr>
              )}
            </T.TBody>
          </T.Table>
        </T.TableContainer>
        {!showOnlySelected && listInfo && (
          <Pagination
            listInfo={listInfo}
            currentPagination={currentPagination}
            setCurrentPagination={setCurrentPagination}
          />
        )}
      </T.Container>
      {
        <AccountDetailModal
          data={selectedData}
          modalShow={modalShow}
          setModalShow={setModalShow}
        />
      }
    </>
  );
};
