import React from "react";

import { IconPropsWithSVGProps } from "../type";

const Community = (props: IconPropsWithSVGProps) => (
  <svg
    width="10"
    height="12"
    viewBox="0 0 10 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 0.75C0 0.551088 0.07266 0.360322 0.201995 0.21967C0.331331 0.0790178 0.506747 0 0.689655 0H9.31034C9.49325 0 9.66867 0.0790178 9.79801 0.21967C9.92734 0.360322 10 0.551088 10 0.75V2.25C10 2.44891 9.92734 2.63968 9.79801 2.78033C9.66867 2.92098 9.49325 3 9.31034 3C9.12744 3 8.95202 2.92098 8.82268 2.78033C8.69335 2.63968 8.62069 2.44891 8.62069 2.25V1.5H5.68966V10.5H6.37931C6.56222 10.5 6.73763 10.579 6.86697 10.7197C6.99631 10.8603 7.06897 11.0511 7.06897 11.25C7.06897 11.4489 6.99631 11.6397 6.86697 11.7803C6.73763 11.921 6.56222 12 6.37931 12H3.62069C3.43778 12 3.26237 11.921 3.13303 11.7803C3.00369 11.6397 2.93103 11.4489 2.93103 11.25C2.93103 11.0511 3.00369 10.8603 3.13303 10.7197C3.26237 10.579 3.43778 10.5 3.62069 10.5H4.31034V1.5H1.37931V2.25C1.37931 2.44891 1.30665 2.63968 1.17731 2.78033C1.04798 2.92098 0.872563 3 0.689655 3C0.506747 3 0.331331 2.92098 0.201995 2.78033C0.07266 2.63968 0 2.44891 0 2.25V0.75Z"
      fill="white"
    />
  </svg>
);

export default Community;
