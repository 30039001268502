import { ProfileTable_account } from '@/components/ProfileTable_account';
import { Margin } from '@/styles/style';
import { useEffect } from 'react';
import * as S from '../style';
import { ModalType } from '../type';

export const AccountDetailModal = ({
  data,
  modalShow,
  setModalShow,
}: ModalType) => {
  useEffect(() => {
    console.log(modalShow);
    if (modalShow) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [modalShow]);
  const handleClose = () => {
    setModalShow(false);
  };
  return (
    <>
      {modalShow && (
        <S.Background onClick={() => handleClose()}>
          <S.Container width='500px' onClick={(e) => e.stopPropagation()}>
            <S.Header>유저 정보</S.Header>
            <S.InputContainer>
              <S.Title>이름</S.Title>
              <S.Input disabled value={data?.nickname || data?.userName} />
            </S.InputContainer>
            <S.InputContainer>
              <S.Title>이메일</S.Title>
              <S.Input disabled value={data?.email} />
            </S.InputContainer>
            <S.InputContainer>
              <S.Title>연락처</S.Title>
              <S.Input disabled value={data?.phoneNumber} />
            </S.InputContainer>
            <S.InputContainer>
              <S.Title>가입일</S.Title>
              <S.Input disabled value={data?.createdAt?.split('T')[0]} />
            </S.InputContainer>
            <S.InputContainer>
              <S.Title>가입한 프로필</S.Title>
              <ProfileTable_account accountId={data.accountId} />
            </S.InputContainer>
          </S.Container>
        </S.Background>
      )}
    </>
  );
};
