import useCustomTable from '@/hooks/useCustomTable';
import { Button, Flex, NullDataBox, Span } from '@/styles/style';
import * as T from '@/styles/TableStyle';
import { useState } from 'react';
import Pagination from '@/components/Pagination';
import { selectItem } from '../common';
import { TableType } from '../type';
import { customFetch } from '@/constants/customFetch';
import { useLocation } from 'react-router-dom';
const SessionTable = ({
  selectedItemList,
  setSelectedItemList,
  canCheck,
  showOnlySelected,
}: TableType) => {
  const [currentPagination, setCurrentPagination] = useState<number>(1);
  const [orderBy, setOrderBy] = useState<'desc' | 'asc'>('desc');
  const location = useLocation();
  const pathname = location.pathname;
  console.log(pathname);

  const { list, setList, listInfo, offset, setOffset, getData } =
    useCustomTable({
      itemType: 'session',
      showOnlySelected,
      selectedItemList,
      currentPagination,
      orderBy,
    });

  const deleteSession = async (sessionId: string) => {
    const response = await customFetch({
      url: `/api/admin/center/${sessionId}/delete`,
    });
    if (response.status && response.status !== 200) {
      alert('세션 삭제에 실패했습니다');
    } else {
      await getData();
    }
  };
  return (
    <>
      <Flex justifyContents='space-between'>
        <Span color='var(--c100)'>
          총 {listInfo?.totalCount || 0}개의 데이터
        </Span>
      </Flex>
      <T.Container>
        <T.TableContainer>
          <T.Table>
            <T.THead>
              <T.Tr>
                {canCheck && <T.Th width='50px'>선택</T.Th>}
                <T.Th width='50px' textAlign='center'>
                  번호
                </T.Th>
                <T.Th>세션명</T.Th>
                <T.Th>세션소개</T.Th>
              </T.Tr>
            </T.THead>

            <T.TBody>
              {list.length > 0 ? (
                list.map((item, index) => {
                  return (
                    <T.Tr key={index}>
                      {canCheck && selectedItemList && setSelectedItemList && (
                        <T.Td>
                          <input
                            type='checkbox'
                            checked={selectedItemList.some(
                              (selectedItem) =>
                                selectedItem.sessionId === item.sessionId
                            )}
                            onChange={() =>
                              selectItem(
                                item,
                                selectedItemList,
                                setSelectedItemList
                              )
                            }
                          />
                        </T.Td>
                      )}
                      <T.Td textAlign='center'>{offset + index + 1}</T.Td>
                      <T.Td>{item.title}</T.Td>
                      <T.Td whiteSpace='wrap'>{item.introduceText}</T.Td>
                      <T.Td>
                        <Button
                          backgroundColor='var(--semantic-red)'
                          color='white'
                          border='none'
                          onClick={() =>
                            window.confirm(`세션을 삭제하시겠습니까?`) &&
                            deleteSession(item.sessionId)
                          }
                        >
                          삭제
                        </Button>
                      </T.Td>
                    </T.Tr>
                  );
                })
              ) : (
                <T.Tr>
                  <T.Td colSpan={3}>
                    <NullDataBox>데이터가 없습니다</NullDataBox>
                  </T.Td>
                </T.Tr>
              )}
            </T.TBody>
          </T.Table>
        </T.TableContainer>
        {!showOnlySelected && listInfo && (
          <Pagination
            listInfo={listInfo}
            currentPagination={currentPagination}
            setCurrentPagination={setCurrentPagination}
          />
        )}
      </T.Container>
    </>
  );
};
export default SessionTable;
