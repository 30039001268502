import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  height: 64px;
  display: flex;
  justify-content: space-between;
  color: var(--primary);
  background-color: #26272f;
  display: flex;
  align-items: center;
  padding: 13px 20px 13px 0;
  font-size: 1.3rem;
  font-weight: bold;
  z-index: 9999;
`;
export const SearchInput = styled.input`
  width: 324px;
  height: 38px;
  border-radius: 3px;
  background-color: transparent;
  border: 1px solid var(--c700);
  padding: 10px 14px;
`;

export const Logo = styled.div`
  width: 177px;
  margin: 30px 20px;
  img {
    width: 100%;
  }
`;
export const LogoutButton = styled.button`
  width: 100px;
  height: 30px;
  color: #6c7293;
  border: 1px solid #6c7293;
  border-radius: 4px;
`;
