import React from "react";

import { IconPropsWithSVGProps } from "../type";

const GreenArrow = (props: IconPropsWithSVGProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M18 6L6 18M18 16V6H8"
      stroke="#00D25B"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default GreenArrow;
