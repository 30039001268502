import { ProfileTable_search } from '@/components/ProfileTable_search';
import CenterTable from '@/components/Tables/CenterTable';
import { customFetch } from '@/constants/customFetch';
import { CheckBoxContainer, Flex, Margin, Span, Button } from '@/styles/style';
import { useEffect, useState } from 'react';
import * as S from '../style';
import { ModalType } from '../type';
import { SelectButton, SelectButtonContainer, WhiteBorderBox } from './style';

const AddPushModal = ({ data, modalShow, setModalShow }: ModalType) => {
  const [title, setTitle] = useState<string>('');
  const [content, setContnet] = useState<string>('');
  const [deepLink, setDeepLink] = useState<string>('');
  const [target, setTarget] = useState<string[]>([]);
  const [isAllSent, setIsAllSent] = useState<boolean>(false);
  const [selectedItemList, setSelectedItemList] = useState<any>([]);
  const [keyword, setKeyword] = useState<string>('');
  const [selectedType, setSelectedType] = useState<'center' | 'profile'>(
    'profile'
  );
  useEffect(() => {
    if (modalShow) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [modalShow]);
  useEffect(() => {
    setSelectedItemList([]);
    setKeyword('');
  }, [selectedType]);
  const handleClose = () => {
    setModalShow(false);
    setKeyword('');
    initModal();
  };
  const initModal = () => {
    setTitle('');
    setContnet('');
    setDeepLink('');
    setSelectedItemList([]);
  };
  const submit = async () => {
    if (!checkValidate()) {
      return;
    }
    const tUserlist = selectedItemList.map((v: any) => v.profileId);
    const response = await customFetch({
      url: `/api/admin/adminPushSender`,
      body: {
        targetType: isAllSent ? 'all' : 'multiple',
        tUserlist: isAllSent ? [] : tUserlist,
        title,
        body: content,
        deeplinkPath: deepLink,
      },
    });
    if (response.status && response.status !== 200) {
      alert('푸시 발송에 실패했습니다');
    } else {
      alert('푸시 발송에 성공하였습니다');
      handleClose();
      window.location.reload();
    }
  };
  const submit_center = async () => {
    console.log(selectedItemList);
    if (!checkValidate()) {
      return;
    }
    const response = await customFetch({
      url: `/api/admin/adminCenterPushSender`,
      body: {
        targetType: 'center',
        centerId: selectedItemList[0].centerId,
        title,
        body: content,
        deeplinkPath: deepLink,
      },
    });
    if (response.status && response.status !== 200) {
      alert('푸시 발송에 실패했습니다');
    } else {
      alert('푸시 발송에 성공하였습니다');
      handleClose();
      window.location.reload();
    }
  };
  const checkValidate = () => {
    if (title === '') {
      alert('제목을 입력해주세요');
      return false;
    }
    if (content === '') {
      alert('내용을 입력해주세요');
      return false;
    }
    if (deepLink === '') {
      alert('딥링크를 입력해주세요');
      return false;
    }
    return true;
  };
  return (
    <>
      {modalShow && (
        <S.Background onClick={() => handleClose()}>
          <S.Container width='700px' onClick={(e) => e.stopPropagation()}>
            <S.Header>앱 푸시 추가하기</S.Header>
            <S.InputContainer>
              <S.Title>제목</S.Title>
              <S.Input
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </S.InputContainer>
            <S.InputContainer>
              <S.Title>내용</S.Title>
              <S.Input
                value={content}
                onChange={(e) => setContnet(e.target.value)}
              />
            </S.InputContainer>
            <S.InputContainer>
              <S.Title>딥링크</S.Title>
              <S.Input
                value={deepLink}
                onChange={(e) => setDeepLink(e.target.value)}
              />
            </S.InputContainer>
            <S.InputContainer>
              <S.Title>발송 대상</S.Title>
              {selectedType === 'profile' && (
                <CheckBoxContainer
                  onClick={() => setIsAllSent((prev) => !prev)}
                >
                  <input type='checkbox' checked={isAllSent} />
                  <Span>모두에게 발송</Span>
                </CheckBoxContainer>
              )}
              {!isAllSent && (
                <>
                  <SelectButtonContainer>
                    <SelectButton
                      onClick={() => setSelectedType('profile')}
                      isActive={selectedType === 'profile'}
                    >
                      회원
                    </SelectButton>
                    <SelectButton
                      onClick={() => setSelectedType('center')}
                      isActive={selectedType === 'center'}
                    >
                      센터
                    </SelectButton>
                  </SelectButtonContainer>
                  <WhiteBorderBox>
                    {selectedType === 'profile' ? (
                      <ProfileTable_search
                        canCheck={true}
                        selectedItemList={selectedItemList}
                        setSelectedItemList={setSelectedItemList}
                        canSearch={true}
                        keyword={keyword}
                        setKeyword={setKeyword}
                      />
                    ) : (
                      <>
                        <Span color='var(--c300)' margin='0 0 10px 0'>
                          센터는 한 번에 한 센터에만 발송할 수 있습니다
                        </Span>
                        <CenterTable
                          canCheck={true}
                          selectedItemList={selectedItemList}
                          setSelectedItemList={setSelectedItemList}
                          canSearch={true}
                          keyword={keyword}
                          setKeyword={setKeyword}
                          onlyOneSelected={true}
                        />
                      </>
                    )}
                  </WhiteBorderBox>
                </>
              )}
            </S.InputContainer>
            <S.AddButton
              onClick={() => {
                if (selectedType === 'profile') {
                  submit();
                } else {
                  submit_center();
                }
              }}
            >
              추가하기
            </S.AddButton>
          </S.Container>
        </S.Background>
      )}
    </>
  );
};
export default AddPushModal;
