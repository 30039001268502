import { Flex } from '../../styles/style';
import { signOut } from '@firebase/auth';
import { auth } from '../../firebase';
import { useNavigate } from 'react-router-dom';
import { Container, SearchInput, Logo, LogoutButton } from './style';
import Icon from '../Icon';
function Header() {
  const navigate = useNavigate();

  const handleSignOut = () => {
    signOut(auth)
      .then(() => {
        console.log('로그아웃 성공');
        navigate('/');
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <Container>
      <Logo>
        <Icon icon='LogoText' />
      </Logo>
      {/* <LogoutButton type='button' onClick={handleSignOut}>
        로그아웃
      </LogoutButton> */}
    </Container>
  );
}
export default Header;
