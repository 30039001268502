import styled from 'styled-components';
export const Container = styled.div`
  position: fixed;
  left: 0px;
  width: 245px;
  height: 100vh;
  overflow: auto;
  min-width: 245px;
  min-height: 100vh;
  background-color: var(--background-color);
  box-shadow: 10px 0 15px -2px rgba(0, 0, 0, 0.8);
  z-index: 10;
  display: flex;
  flex-direction: column;
`;
export const ProfileImage = styled.div`
  position: relative;
  width: 35px;
  height: 35px;
`;
export const ProfileImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 100%;
  overflow: hidden;
  > img {
    width: 100%;
    height: 100%;
  }
`;
export const List = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  color: #6c7293;
  font-size: 1rem;
  justify-content: space-between;
`;
export const Title = styled.div`
  display: flex;
  align-items: center;
  height: 45px;
  padding: 8px 20px;
  :not(:first-child) {
    margin-top: 10px;
  }
`;
type ItemType = {
  isSelected?: boolean;
};
export const Item = styled.div<ItemType>`
  display: flex;
  align-items: center;
  gap: 8px;
  height: 55px;
  padding: 8px 20px;
  color: ${({ isSelected }) => (isSelected ? 'white' : ' #6c7293')};
  cursor: pointer;
`;
export const LogOutItem = styled(Item)`
  /* margin-top: 50px; */
  flex: 1;
  padding-bottom: 85px;
  -webkit-box-align: baseline;
  align-items: flex-end;
  padding-left: 30px;
`;
export const ActiveItem = styled(Item)`
  width: 223px;
  color: white;
  background-color: #1d1e24;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  border-left: 3px solid var(--primary);
`;
export const GreenCircle = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background-color: var(--primary);
  border: 2px solid #191c24;
  position: absolute;
  top: 25px;
  left: 25px;
`;
export const IconWrapper = styled.div`
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  background-color: #2a2d3a;
`;

export const SubItem = styled(Item)<{ active?: boolean }>`
  padding: 8px 55px;
  height: 45px;
  gap: 15px;
  font-weight: ${({ active }) => active && '500'};
  color: ${({ active }) => active && 'white'};
`;
export const Dot = styled.div`
  border-radius: 100%;
  width: 3px;
  height: 3px;
  color: white;
  background-color: white;
`;
